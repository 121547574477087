import { create } from "zustand";
import type { Resource } from "types/__generated__/graphql";

interface MixerState {
  mixerEnabled: boolean;
  mixerVolume: number;
  mixerTrack: Resource | null;
  mixers: Resource[] | null;
  modifiedMixers: Resource[] | null;
  setMixerEnabled: (enabled: boolean) => void;
  setMixerVolume: (volume: number) => void;
  setMixerTrack: (mixer: Resource | null) => void;
  setMixers: (mixers: Resource[]) => void;
  setModifiedMixers: (mixers: Resource[]) => void;
}

const useMediaMixerStore = create<MixerState>((set) => ({
  mixerEnabled: false,
  mixerVolume: 50,
  mixerTrack: null,
  mixers: null,
  modifiedMixers: null,
  setMixerEnabled: (enabled) => set({ mixerEnabled: enabled }),
  setMixerVolume: (volume) => set({ mixerVolume: volume }),
  setMixerTrack: (mixer) => set({ mixerTrack: mixer }),
  setMixers: (mixers) => set({ mixers }),
  setModifiedMixers: (mixers) => set({ modifiedMixers: mixers }),
}));

export default useMediaMixerStore;
