import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import { apiConfig } from "config/apiConfig";
import { getAuth0Client, microsoftAuthUrl } from "config/authConfig";
import { authLoginPopUp } from "utils/authHelper";
import { AuthRequirement, getAuthRequirement } from "./graphqlOperationsHelper";
export async function getToken(
  { operationName }: { operationName: string | null } = { operationName: null }
): Promise<string | null> {
  try {
    const auth0 = await getAuth0Client();
    const accessToken = await auth0.getTokenSilently();
    return accessToken;
  } catch (error: any) {
    console.warn(error);
    const authRequirement = getAuthRequirement(operationName);
    if (
      (authRequirement !== AuthRequirement.OPTIONAL &&
        error.error === "login_required") ||
      error.error === "consent_required"
    ) {
      return await authLoginPopUp(microsoftAuthUrl);
    }
    console.error("Unexpected error during token retrieval:", error);
    return null;
  }
}
const httpLink = createHttpLink({
  uri: apiConfig.platformApiEndpoint,
});
const restLink = new RestLink({
  uri: apiConfig.dsApiEndpoint,
});
const authLink = setContext(async (operation, { headers }) => {
  const token = await getToken({
    operationName: operation.operationName ?? null,
  });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-mv-app": "mv-web",
    },
  };
});
const combinedLink = authLink.concat(restLink.concat(httpLink));
export const apolloClient = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache({}),
});
export function clearEntireCache() {
  apolloClient.clearStore();
}
export function clearSpecificType(fieldName: string) {
  apolloClient.cache.evict({ fieldName });
  apolloClient.cache.gc();
}
