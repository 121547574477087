import { useRef } from "react";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useAssignedQuests from "hooks/useAssignedQuests";
import useLibrary from "hooks/useLibrary";
import useUserProfileDetails from "hooks/useUserProfileDetails";
import useSoundscapesOfTheWeek from "hooks/useSoundscapesOfTheWeek";
import useMeditationsOfTheDay from "hooks/useMeditationsOfTheDay";
import { QuestStatus } from "types/interfaces";
import FeaturedSection from "./sections/FeaturedSection";
import ContinueLearningSkeleton from "components/loading/ContinueLearningSkeleton";
import { NavigationEnum } from "router/navigationType";
import { AssignedToYouSection } from "./sections/AssignedToYouSection";
import { ContinueLearningSection } from "./sections/ContinueLearningSection";
import { HeroSection } from "./sections/HeroSection";
import { FreeQuestsSection } from "./sections/FreeQuestsSection";
import { PickedForYouSection } from "./sections/PickedForYouSection";
import { RecommendedForYou } from "./sections/RecommendedForYou";

export default function HomeTab() {
  const { userAccessState } = useUserState();
  const MeditationsRef = useRef<HTMLDivElement>(null);
  const SoundscapesRef = useRef<HTMLDivElement>(null);

  const { profileData, loading: profileDataLoading } = useUserProfileDetails();
  const { data: questsInProgress, loading: questsInProgressLoading } =
    useLibrary({
      first: 4,
      status: QuestStatus.InProgress,
    });

  const { data: assignedQuests, loading: assignedQuestsLoading } =
    useAssignedQuests({
      first: 5,
    });

  const {
    data: meditationsOfTheDay,
    loading: fetchingMeditationsOfTheDay,
    error: fetchingMeditationOfTheDayError,
  } = useMeditationsOfTheDay();
  const {
    data: soundscapeOfTheWeek,
    loading: fetchingSoundscapeOfTheWeek,
    error: fetchingSoundscapeOfTheWeekError,
  } = useSoundscapesOfTheWeek();

  if (
    userAccessState === UserAccessStateEnum.Premium &&
    (profileDataLoading || questsInProgressLoading || assignedQuestsLoading)
  )
    return <ContinueLearningSkeleton />;

  const premiumContent = (
    <>
      <ContinueLearningSection
        profile={profileData}
        questsAssigned={assignedQuests}
        questsInProgress={questsInProgress}
      />
      {assignedQuests && assignedQuests.length > 0 && (
        <AssignedToYouSection questsAssigned={assignedQuests} />
      )}
    </>
  );

  const executeScroll = (ref: number) =>
    ref === 1
      ? MeditationsRef?.current?.scrollIntoView({ behavior: "smooth" })
      : SoundscapesRef?.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <div className="flex flex-col gap-12 md:gap-16 lg:gap-16 pt-4">
      {!(
        (questsInProgress?.length ?? 0) > 0 &&
        userAccessState === UserAccessStateEnum.Premium
      ) && <HeroSection onMoreDetail={executeScroll} />}
      {userAccessState === UserAccessStateEnum.Premium && premiumContent}
      <PickedForYouSection />
      {userAccessState === UserAccessStateEnum.Freemium && (
        <FreeQuestsSection />
      )}
      <div id="MeditationRef" ref={MeditationsRef}>
        <FeaturedSection
          data={meditationsOfTheDay}
          isFetching={fetchingMeditationsOfTheDay}
          error={fetchingMeditationOfTheDayError}
          copy={{
            title: "Meditations of the day",
            description:
              "Discover individual meditations designed to help you focus, relax, and recharge whenever you need a moment of mindfulness.",
          }}
          type="meditations"
          handleDiscoverMoreNavigation={NavigationEnum.Meditations}
        />
      </div>
      <div id="SoundscapesRef" ref={SoundscapesRef}>
        <FeaturedSection
          data={soundscapeOfTheWeek}
          isFetching={fetchingSoundscapeOfTheWeek}
          error={fetchingSoundscapeOfTheWeekError}
          copy={{
            title: "Soundscapes of the week",
            description:
              "Experience the power of sound to improve well-being and job performance. Our soundscapes offer a moment of escape without leaving your desk.",
          }}
          type="soundscapes"
          handleDiscoverMoreNavigation={NavigationEnum.Soundscapes}
        />
      </div>
      <RecommendedForYou />
    </div>
  );
}
