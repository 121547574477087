import HubspotForm from "./components/HubspotForm";
import { Button } from "components/Button";
import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
// import { ArrowLeftCircle } from "components/icons/ArrowLeftCircle";
import Icons from "components/icons/Icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useUserProfile from "hooks/useUserProfile";
import Preloader from "components/loading/Preloader";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { Combobox } from "components/ComboBox";
import useCityList from "hooks/useCityList";
import { ICity, IOption, TIOption } from "types/interfaces";

interface FormValues {
  companyName: string;
  location: string;
  website: string;
}

const portalId = "619497";
const formId = "a1cd346d-5de8-43c2-acd8-dc0221209d35";
const CompanyNameForm = () => {
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState(""); // <-- Search key state
  const [location, setLocation] = useState<string>("");
  const [query, setQuery] = useState<string | null>(null);
  const currentYear = new Date().getFullYear();

  const formError = false;
  // console.log(isFormSubmitted);
  const { data, loading, error } = useUserProfile();
  const {
    cityData,
    loading: loadingCities,
    error: getCitiesError,
  } = useCityList(100, searchKey);

  useEffect(() => {
    if (searchKey.trim() !== "") {
      setQuery(searchKey);
    } else {
      setQuery(null); // Optional: set to null or undefined when no searchKey to skip API call
    }
  }, [searchKey]);

  if (!data && loading) <div>Loading</div>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const onCitySelect = (selected: IOption) => {
    // const city = cityData?.find(
    //   (item: ICity) => item.cityCode === selected.value
    // );
    if (selected) {
      setLocation(selected.name); // Update location state
      setValue("location", selected.name); // Set form location field
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const response = await axios.post(hubspotUrl, {
      fields: [
        {
          name: "email",
          value: data.currentProfile.email,
        },
        {
          name: "firstname",
          value: data.currentProfile.firstName,
        },
        {
          name: "lastname",
          value: data.currentProfile.lastName,
        },
        {
          name: "0-2/name",
          value: formData.companyName,
        },
        {
          name: "country",
          value: formData.location || "",
        },
        {
          name: "0-2/website",
          value: formData.website || "",
        },
      ],
      "context": {
        "pageUri": "https://teams.microsoft.com/v2/",
        "pageName": "Workway-MS-Teams"
      }
    });

    navigate("/loading", {
      state: {
        responseMessage: [
          "Welcome to Workway!",
          "We’re excited for you to get started.",
        ],
      },
    });
  };
  // console.log(cityData);

  //[ ] add website url vaildation
  return (
    <div
      className="flex sm:px-3 flex-col bg-brown-50 gap-y-6 pt-2 min-h-screen sm:w-full px-4"
      id="companyForm"
    >
      <div>
        <Button
          variant={"icon"}
          className="py-[6px] px-0"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icons name="arrow-left-circle-outlined" fill="black" width={20} height={20} />
          <p className="px-1 button-text-medium">Back</p>
        </Button>
      </div>
      <div className="max-w-[680px] self-center">
        <div className="  flex flex-col items-center gap-y-4">
          <WorkwayLogoFull width="160" height="26" />
          <div
            className={`title-8 text-center flex flex-col items-center gap-y-1 ${isFormSubmitted ? "hidden" : "block"
              }`}
          >
            <p
              className={`title-8 text-center ${isFormSubmitted ? "hidden" : "block"
                }`}
            >
              Your organization doesn't have an active account yet. Let's get
              you started.
            </p>
            <p className="caption-disclaimer text-center">
              <span>You are currently using</span>
              <span className="title-9 text-content-quaternary">
                {" " + data?.currentProfile?.email || "Loading..."}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center sm:mx-auto max-w-[600px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-light rounded-[16px] sm:p-10 p-4 w-full flex flex-col gap-6"
        >
          <div>
            <h4 className="heading-6">Fill out your company details</h4>
            <p className="body-small">
              You’ll get connected to Workway right after this step.
            </p>
          </div>

          {/* Company Name Field */}
          <div className="flex flex-col gap-2">
            <label className="title-8">
              Company name<span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              {...register("companyName", {
                required: "Company name is required",
              })}
              className="w-full h-[40px] focus:ring-0 focus:outline-none focus:border-brown-500 p-2 border border-brown-400 rounded-lg"
              placeholder="Enter your company name"
            />
            {errors.companyName && (
              <p className="text-red-500 text-sm ">
                {errors.companyName.message}
              </p>
            )}
          </div>

          {/* Location Field */}
          <div className="flex flex-col gap-2">
            <label className="block title-8 ">Location</label>
            {/* <input
              type="text"
              {...register("location")}
              className="w-full h-[40px] p-2 border border-brown-400 rounded-md focus:ring-0 focus:outline-none focus:border-brown-500"
              placeholder="Enter your location"
            /> */}
            <Combobox
              options={cityData?.map((city: any) => ({
                name: city.name,
                value: city.country.iso3Code,
                adminDivision: city.adminDivision?.name,
              }))}
              onSelect={onCitySelect}
              placeholderText="Select your city"
              onSearch={setSearchKey}
            />
          </div>

          {/* Website Field */}
          <div className="flex flex-col gap-2 w-full">
            <label className=" title-8">Website</label>
            <div className="flex w-full items-center border border-y-brown-400 rounded-lg ">
              <span className="text-gray-500 p-2 bg-gray-100 border-r border-[rgba(0, 0, 0, 0.12)] body-small text-content-quaternary">
                https://
              </span>
              <input
                type="name"
                {...register("website")}
                className="p-2 w-full rounded-lg h-[40px] focus:ring-0 focus:outline-none   "
                placeholder="www.example.com"
              />
            </div>
            {errors.website && (
              <p className="text-red-500 caption-disclaimer">
                {errors.website.message}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full h-12 rounded-[100px] py-2 text-white bg-red-600 font-semibold"
          >
            Connect me now
          </button>
        </form>
        <div className="flex flex-col w-full leading-4">
          <p className="text-cool-grey-500 caption-disclaimer m-auto tracking-wider">
            Need help?
          </p>
          <p className="text-cool-grey-500 caption-disclaimer text-center tracking-wider">
            Visit our{" "}
            <a className="underline cursor-pointer" href="">
              Support Center
            </a>
          </p>
        </div>
      </div>
      <p className="text-center caption-disclaimer text-[#979CA5] mb-6">
        © {currentYear} Workway by Mindvalley
      </p>
    </div>
  );
};

export default CompanyNameForm;
