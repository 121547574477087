import { Skeleton } from "components/Skeleton";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";

export function DetailCategoriesHeroBanner({
  copy,
  bannerImageUrl,
  bannerVideoUrl,
  isFetching,
  handleInnerNavigation,
  handleOuterNavigation,
}: {
  copy: {
    title: string;
    description?: string;
    outerNavigationCTA: string;
  };
  bannerImageUrl?: string;
  bannerVideoUrl?: string;
  isFetching: boolean;
  handleInnerNavigation: () => void;
  handleOuterNavigation: () => void;
}) {
  return (
    <section>
      {isFetching ? (
        <div className="grid gap-3">
          <Skeleton className="flex h-[480px] xl:h-[577px] w-full rounded-2xl xl:rounded-3xl relative bg-cool-grey-200">
            <Skeleton className="bg-cool-grey-350 absolute bottom-6 left-6 grid gap-3 max-w-[460px] w-full p-6">
              <Skeleton className="h-8" />
              <Skeleton className="h-5" />
              <Skeleton className="h-5" />
              <Skeleton className="h-5 w-6/12" />
            </Skeleton>
          </Skeleton>
          <Skeleton className="h-6 w-44 ml-auto" />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <div className="flex h-[480px] xl:h-[577px] w-full rounded-2xl xl:rounded-3xl relative">
            {bannerImageUrl ? (
              <img
                src={bannerImageUrl}
                alt="Banner"
                className="absolute size-full object-cover rounded-3xl"
              />
            ) : (
              <video
                className="absolute size-full object-cover rounded-3xl"
                autoPlay
                loop
                muted
                disablePictureInPicture
                disableRemotePlayback
                controlsList="nodownload noremoteplayback nofullscreen"
                playsInline
              >
                <source src={bannerVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div className="w-full relative self-end flex flex-col justify-end p-6">
              <div className="absolute xl:hidden rounded-2xl bottom-0 left-0 size-full bg-gradient-to-t from-black" />

              <div className="flex justify-between items-end">
                <div className="xl:bg-black-80a xl:backdrop-blur-[6px] w-full xl:max-w-[460px] rounded-2xl grid gap-3 xl:p-6 z-10">
                  <h4 className="heading-2 text-cool-grey-200">{copy.title}</h4>
                  <p className="body-small text-cool-grey-200 line-clamp-[2] md:line-clamp-none">
                    {copy.description}
                  </p>
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="roundedIcon"
                  onClick={handleInnerNavigation}
                  className="hidden xl:flex p-2 bg-white-90a hover:bg-white rounded-full h-14 w-14 gap-[10px]"
                >
                  <Icons
                    name="arrow-down-right-filled"
                    className="size-8 text-cool-grey-700"
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full">
            <Button variant="ghost" onClick={handleOuterNavigation}>
              <span>{copy.outerNavigationCTA}</span>
              <Icons
                name="chevron-right-filled"
                className="size-3.5"
                fill="black"
              />
            </Button>
          </div>
        </div>
      )}
    </section>
  );
}
