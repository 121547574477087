import { Button } from "@fluentui/react-components";
import type { B2bQuest } from "types/__generated__/graphql";

export default function QuestCardSidePanelView({
  quest,
  handleClick,
  frameContext,
}: {
  quest: B2bQuest;
  handleClick: () => void;
  frameContext: string | null;
}) {
  return (
    <div>
      <figure>
        <img
          src={quest.coverAsset?.url}
          alt={quest.name}
          role="presentation"
          className="aspect-video rounded-lg"
        />
        <figcaption className="sr-only">{quest.name}</figcaption>
      </figure>

      <div className="grid space-y-2 mt-1.5">
        <div className="grid sapce-y-1">
          <h3 className="text-[14px] font-semibold">{quest.name}</h3>
          {quest.authors && quest.authors.length > 0 && (
            <p className="text-[10px] text-[#616161]">
              {quest.authors[0].name}
            </p>
          )}
        </div>

        <div className="flex space-x-1">
          <Button size="small" onClick={handleClick}>
            {frameContext === "content" ? " Watch" : " Watch together"}
          </Button>
          {/* <Button size="small">Add to queue</Button> */}
        </div>
      </div>
    </div>
  );
}
