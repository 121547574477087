import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useChannelBySlug from "hooks/useChannelBySlug";
import { DetailCategoriesHeroBanner } from "components/soundscapesAndMeditations/DetailCategoriesHeroBanner";
import { CollectionsListGridSection } from "components/soundscapesAndMeditations/CollectionsListGridSection";
import { CategoryListGridSection } from "components/soundscapesAndMeditations/CategoryListGridSection";
import {
  CollectionHighlightStatusFilterEnum,
  type B2bCollection,
} from "types/__generated__/graphql";

export default function MeditationsCategoriesView() {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const exploreCategoriesRef = useRef<HTMLDivElement | null>(null);
  const [currentCategory, setCurrentCategory] = useState<B2bCollection | null>(
    null
  );

  const { data: collectionsData, loading: fetchingData } = useChannelBySlug({
    slug: "b2b-meditations-categories",
    highlightStatus: CollectionHighlightStatusFilterEnum.NotFeatured,
  });

  const filteredCategoriesData = useMemo(() => {
    if (!collectionsData?.collections) return null;
    return (
      collectionsData.collections.find(
        (collection) => collection.slug === slug
      ) ?? null
    );
  }, [collectionsData, slug]);

  const heroTitle = slug
    ? currentCategory?.title ?? filteredCategoriesData?.title ?? "Category"
    : collectionsData?.title ?? "Meditation Categories";

  const heroDescription = slug
    ? currentCategory?.description ??
      filteredCategoriesData?.description ??
      "Explore the selected category for more meditations."
    : collectionsData?.description ??
      "Discover the transformative power of sound for working professionals. Our library of meditations offers the perfect escape from the demands of work. Whether you need to relax, focus, or boost your productivity, we have the soundscape for you.";

  const heroImageUrl = slug
    ? currentCategory?.landscapeCoverAsset?.url ??
      filteredCategoriesData?.landscapeCoverAsset?.url ??
      ""
    : "";

  function handleChangeCategory(category: B2bCollection) {
    setCurrentCategory(category);
  }

  function handleScrollToCategories() {
    exploreCategoriesRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="flex flex-col gap-10 container mx-auto">
      <DetailCategoriesHeroBanner
        copy={{
          title: heroTitle,
          description: heroDescription,
          outerNavigationCTA: "Explore collections",
        }}
        bannerVideoUrl={collectionsData?.trailerLoopingAsset?.url}
        bannerImageUrl={heroImageUrl}
        isFetching={fetchingData || !collectionsData}
        handleInnerNavigation={handleScrollToCategories}
        handleOuterNavigation={() => navigate("/meditations/collections")}
      />

      {slug ? (
        <CategoryListGridSection
          type="meditations"
          copy={{
            title: `Soundscapes from ${
              currentCategory?.title ??
              filteredCategoriesData?.title ??
              "Category"
            }`,
          }}
          sectionRef={exploreCategoriesRef}
          isFetching={fetchingData || !filteredCategoriesData}
          collectionsData={collectionsData}
          filteredCategoriesData={currentCategory ?? filteredCategoriesData}
          handleCategoryChange={handleChangeCategory}
        />
      ) : (
        <CollectionsListGridSection
          type="meditations"
          copy={{
            title: "All categories",
          }}
          sectionRef={exploreCategoriesRef}
          isFetching={fetchingData || !collectionsData}
          collectionsData={collectionsData?.collections ?? []}
          handleCategoryNavigation={(slug) =>
            navigate(`/meditations/categories/${slug}`)
          }
        />
      )}
    </div>
  );
}
