import { useState } from "react";
import useHarmonizedMeditation from "hooks/useHamonizedMeditation";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import MediaCardComponent from "components/MediaCardComponent";
import { useUserState } from "context/userContext";
import useScreenType from "hooks/useScreenType";

export const SoundscapeMixerSection = () => {
  const { userAccessState } = useUserState();
  const screenType = useScreenType();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [currentItem, setCurrentItem] = useState<number>(0);

  const { data } = useHarmonizedMeditation();

  function nextPreviousArrowHandler(value: number) {
    const newIndex = Math.max(
      0,
      Math.min(currentItem + value, (data?.length ?? 0) - 1)
    ); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselApi?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselApi?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  if (!data) return null;

  return (
    <div className="flex relative flex-col w-xl rounded-3xl gap-6 md:gap-9 lg:gap-12 px-6 py-8 md:px-10 md:py-12 bg-cover bg-no-repeat bg-[url('https://assets.mindvalley.com/api/v1/assets/4c0172c2-2c99-4457-908a-bf2a4ad01101.jpg')]">
      <div className="absolute inset-x-0 bottom-0 h-[75%] bg-gradient-to-t from-black to-transparent rounded-3xl" />

      <div className="flex flex-col gap-1 w-auto md:max-w-[616px] lg:max-w-[803px]">
        <div className="title-10 text-teal-200">SOUNDSCAPE MIXER</div>
        <div className="heading-1 text-white">
          Harmonize meditations with soundscapes
        </div>
        <div className="body-small text-cool-grey-200 w-auto md:max-w-[616px] lg:max-w-[400px]">
          Create your perfect meditation experience by blending calming
          soundscapes with guided meditations. Tailor your audio journey to
          match your mood and needs.
        </div>
      </div>
      <div className="flex flex-col overflow-hidden gap-4">
        <div className="hidden md:flex lg:hidden items-start space-x-2 justify-end z-10">
          <CarouselPrevious
            disabled={currentItem === 0}
            onClick={() => {
              nextPreviousArrowHandler(-1);
            }}
            fillColor="#DFE1E5"
          />
          <CarouselNext
            disabled={currentItem === data.length - 1}
            onClick={() => {
              nextPreviousArrowHandler(1);
            }}
            fillColor="#DFE1E5"
          />
        </div>
        <Carousel
          opts={{
            align: "start",
            active: screenType.isMobile || screenType.isTablet,
          }}
          orientation="horizontal"
          className="w-full"
          setApi={setCarouselApi}
        >
          <CarouselContent className="gap-2 md:gap-4 lg:gap-6 ml-0 ">
            {data.map((item) => (
              <CarouselItem
                key={item.id}
                className="basis-auto pl-0 justify-center"
              >
                <MediaCardComponent
                  trackData={item}
                  type="soundscapes"
                  showDuration={true}
                  userAccessState={userAccessState}
                  containerClasses="w-[171px] md:w-[180px]"
                  tileClasses="w-[171px] md:w-[180px] h-[171px] md:h-[180px] rounded-2xl"
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
};
