import { Sheet, SheetContent, SheetTrigger } from "components/Sheet";
import { QuestLandingModal } from "components/quest/questLandingModal/QuestLandingModal";

export const QuestLandingPage = ({
  trigger,
  questArgs,
}: {
  trigger: JSX.Element;
  questArgs: {
    slug?: string;
    language?: string;
  };
}) => {
  return (
    <Sheet>
      <SheetTrigger className="flex justify-center">{trigger}</SheetTrigger>
      <SheetContent
        side="bottom"
        className="bg-white shadow-white/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl  shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
      >
        <QuestLandingModal questArgs={questArgs} />
      </SheetContent>
    </Sheet>
  );
};
