import { useState } from "react";
import { Slider } from "../ui/slider";
import { cn } from "libs/classMerger";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import "components/MediaPlayer.css";
import { useMediaPlayerRefs } from "context/MediaPlayerRefsContext";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaMixerStore from "stores/mediaPlayerMixerStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";

interface MediaPlayerV2VolumeControlProps {
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  customStyle?: React.CSSProperties;
}

export const MediaPlayerVolumeControl = ({
  min = 0,
  max = 100,
  step = 1,
  className,
}: MediaPlayerV2VolumeControlProps) => {
  const isMuted = useMediaPlayerStore((state) => state.isMuted);
  const masterVolume = useMediaPlayerStore((state) => state.masterVolume);
  const trackVolume = useMediaPlayerStore((state) => state.trackVolume);
  const mixerVolume = useMediaMixerStore((state) => state.mixerVolume);
  const setMasterVolume = useMediaPlayerStore((state) => state.setMasterVolume);
  const { audioPlayerRef, mixerAudioPlayerRef } = useMediaPlayerRefs();

  const { toggleMute } = useMediaPlayerHook();
  const [dragVolume, setDragVolume] = useState(masterVolume);

  const onSliderValueChange = (value: number[]) => {
    const newMasterVol = value[0];
    setDragVolume(newMasterVol);

    const normalizedMaster = newMasterVol / 100;
    const normalizedTrackVol = trackVolume / 100;
    const normalizedMixerVol = mixerVolume / 100;

    const effectiveTrackVolume = normalizedTrackVol * normalizedMaster;
    const effectiveMixerVolume = normalizedMixerVol * normalizedMaster;

    if (audioPlayerRef?.current) {
      audioPlayerRef.current.volume = effectiveTrackVolume;
    }

    if (mixerAudioPlayerRef?.current) {
      mixerAudioPlayerRef.current.volume = effectiveMixerVolume;
    }
  };

  const handleDragEnd = () => {
    setMasterVolume(dragVolume);
  };

  const mute = isMuted || dragVolume <= 0;

  return (
    <div className="w-full flex items-center gap-2">
      <Tooltip
        delayDuration={0}
        tooltipContent={
          <p className="caption-disclaimer">{isMuted ? "Unmute" : "Mute"}</p>
        }
      >
        <Button
          variant="icon"
          size="icon"
          className="text-white/70 hover:text-white"
          onClick={() => toggleMute(!isMuted)}
        >
          {mute ? (
            <Icons name="volume-x-outlined" width={20} height={20} />
          ) : (
            <Icons name="volume-2-outlined" width={20} height={20} />
          )}
        </Button>
      </Tooltip>
      <Slider
        defaultValue={[masterVolume]}
        min={min}
        max={max}
        step={step}
        onValueChange={onSliderValueChange}
        onPointerUp={handleDragEnd}
        value={[mute ? 0 : dragVolume]}
        trackClassName="bg-white/25 h-[3px]"
        className={cn("w-full h-[3px] group/volume", className)}
        rangeClassName="bg-white group-hover/volume:bg-red-500"
        thumbClassName="hidden group-hover/volume:block group-hover/volume:bg-red-500 w-2.5 h-2.5 border-0 hover:border-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      />
    </div>
  );
};
