import { LoginWithMicrosoftButton } from "components/LoginWithMicrosoftButton";
import { useUserState } from "context/userContext";
import { useMicrosoftLogin } from "hooks/useMicrosoftLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LockedModal from "./LockedModal";
import { NavigationEnum } from "router/navigationType";

const StickyBar = () => {
  const { userAccessState } = useUserState();
  const handleLogin = useMicrosoftLogin();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickGetInTouch = () => {
    navigate(NavigationEnum.InterestForm);
  };
  return (
    <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center min-h-16 bg-brown-90a backdrop-blur-[6px] w-screen py-1 gap-x-3 gap-y-3">
      <p className="lg:block md:block hidden title-9">Ready to get started?</p>
      {/* <button
        type="button"
        onClick={() => reload()}
        className="flex w-max-[420px] w-fit justify-center items-center px-4 py-3 sm:max-h[18px] space-x-2 border border-cool-grey-300 rounded-lg"
      >
        <MicrosoftIcon size={16} />
        <p className="button-text-medium text-cool-grey-500">
          Log in with Microsoft
        </p>
      </button> */}
      <LoginWithMicrosoftButton handleClick={handleLogin} iconSize={16} customClass="w-fit" />
      <p className="title-9">
        or{" "}
        <button className="underline text-red-600" onClick={handleClickGetInTouch} type="button">
          get in touch
        </button>{" "}
        with our sales team
      </p>
      <LockedModal onCancel={() => setIsModalOpen(false)} open={isModalOpen} userAccessState={userAccessState} />
    </div>
  );
};

export default StickyBar;
