import { useUserState } from "context/userContext";
import {
  useRecommendedB2bMeditationsOfTheDayQuery,
  type RecommendedB2bMeditationsOfTheDayQueryVariables,
  type Resource,
} from "types/__generated__/graphql";

function useMeditationsOfTheDay() {
  const { organizationData } = useUserState();
  const variables: RecommendedB2bMeditationsOfTheDayQueryVariables = {
    first: 4,
    orgId: organizationData?.id?.toString() ?? "0",
  };

  const { data, loading, error } = useRecommendedB2bMeditationsOfTheDayQuery({
    variables,
    // fetchPolicy: "no-cache", // TODO:: There's caching issue with this query
  });

  const meditations = data?.recommendedB2bMeditationsOfTheDay?.edges?.map(
    (edge) => edge.node?.meditation
  ) as Resource[];

  return { data: meditations, loading, error };
}

export default useMeditationsOfTheDay;
