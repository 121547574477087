import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useChannelBySlug from "hooks/useChannelBySlug";
import { DetailCategoriesHeroBanner } from "components/soundscapesAndMeditations/DetailCategoriesHeroBanner";
import { CollectionsListGridSection } from "../../../components/soundscapesAndMeditations/CollectionsListGridSection";
import { CollectionHighlightStatusFilterEnum } from "types/__generated__/graphql";

export default function SoundscapesCollectionsView() {
  const navigate = useNavigate();
  const exploreCollectionsRef = useRef<HTMLDivElement | null>(null);

  const { data, loading: fetchingData } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
  });

  const collectionsData = data?.collections ?? [];

  return (
    <div className="flex flex-col gap-16 container mx-auto">
      <DetailCategoriesHeroBanner
        copy={{
          title: "Soundscape Collections",
          description:
            "Discover personalized soundscapes tailored to every moment of your workday. Immerse yourself in our diverse library of audio environments. Carefully curated to evoke emotions and enhance your mood, our soundscapes offer the perfect escape to enhance your focus, productivity, and well-being.",
          outerNavigationCTA: "Explore categories",
        }}
        bannerVideoUrl={data?.trailerLoopingAsset?.url}
        isFetching={fetchingData || !data}
        handleInnerNavigation={() => {
          exploreCollectionsRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
        handleOuterNavigation={() => navigate("/soundscapes/categories")}
      />

      <CollectionsListGridSection
        type="soundscapes"
        copy={{ title: "Soundscape collections" }}
        sectionRef={exploreCollectionsRef}
        isFetching={fetchingData || !data}
        collectionsData={collectionsData}
        isCollections
        handleCategoryNavigation={(slug) =>
          navigate(`/soundscapes/categories/${slug}`)
        }
      />
    </div>
  );
}
