import { useEffect, useRef } from "react";
import { ProgramsQuestSkeleton } from "components/loading/ProgramsWallSkeleton";
import { QuestCard } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import type { ICategoryNode } from "types/interfaces";
import type { B2bQuest } from "types/__generated__/graphql";

export const QuestsCategorySection = ({
  category,
  selectedLanguageLabel,
  nonEnglishQuestsData,
  englishQuestsData,
  bannerImage,
  loading,
  fetchMoreQuests,
  pageInfo,
}: {
  category?: ICategoryNode;
  selectedLanguageLabel?: string;
  nonEnglishQuestsData: B2bQuest[];
  englishQuestsData: B2bQuest[];
  bannerImage?: string;
  loading: boolean;
  fetchMoreQuests: () => void;
  pageInfo?: {
    hasNextPage: boolean;
    endCursor?: string;
  };
}) => {
  const { isAuthenticated, userAccessState } = useUserState();
  const loaderRef = useRef<HTMLDivElement>(null);

  // Detect when the user scrolls near the bottom
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && pageInfo?.hasNextPage) {
          fetchMoreQuests();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [pageInfo, fetchMoreQuests]);

  if (loading && !nonEnglishQuestsData.length && !englishQuestsData.length)
    return <ProgramsQuestSkeleton />;

  return (
    <div>
      <div className="grid gap-6 xl:gap-12">
        <div className="flex items-center justify-between rounded-2xl">
          <img
            alt={category?.title}
            src={bannerImage}
            className="w-full object-cover h-19 md:h-25"
          />
        </div>
        <div className="w-full flex justify-start items-start flex-col gap-2 lg:flex-row lg:gap-10">
          <div className="w-full xl:w-2/5">
            <h3 className="heading-2">{category?.title}</h3>
          </div>

          <div className="w-full xl:w-3/5">
            <p className="body">{category?.description}</p>
          </div>
        </div>
      </div>

      <div className="mt-10 xl:mt-12 grid gap-6">
        <h5 className="heading-4 text-cool-grey-600">Quests</h5>

        <div className="grid gap-10 xl:gap-12">
          {selectedLanguageLabel !== "English" && (
            <div className="grid gap-6">
              {nonEnglishQuestsData.length > 0 ? (
                <h3 className="text-cool-grey-600 heading-6 mt-3">
                  Available in {selectedLanguageLabel}
                </h3>
              ) : (
                <p className="title-8 mt-3 text-cool-grey-500">
                  None are available in {selectedLanguageLabel}. Try a different
                  category or language.
                </p>
              )}

              {nonEnglishQuestsData.length > 0 && (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
                  {nonEnglishQuestsData?.map((quest) => (
                    <QuestCard
                      key={quest.id}
                      quest={quest}
                      category={category}
                      canHover={isAuthenticated}
                      userAccessState={userAccessState}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="grid gap-6">
            <h3 className="text-cool-grey-600 heading-6">
              Available in English
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
              {englishQuestsData?.map((quest) => (
                <QuestCard
                  key={quest.id}
                  quest={quest}
                  category={category}
                  canHover={isAuthenticated}
                  userAccessState={userAccessState}
                />
              ))}
            </div>
          </div>
        </div>

        <div ref={loaderRef} style={{ height: "20px", margin: "10px 0" }}>
          {loading && <div>Loading more quests...</div>}
        </div>
      </div>
    </div>
  );
};
