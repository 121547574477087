import { useEffect } from "react";
import useMediaPlayerTimer from "hooks/useMediaPlayerTimer";
import useMediaPlayerStore from "stores/mediaPlayerStore";

const MediaPlayerTimerManager = () => {
  const { handleSetTimer } = useMediaPlayerTimer();
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const timerInfinity = useMediaPlayerStore((state) => state.timerInfinity);
  const timerHoursDuration = useMediaPlayerStore(
    (state) => state.timerHoursDuration
  );
  const timerMinutesDuration = useMediaPlayerStore(
    (state) => state.timerMinutesDuration
  );

  // console.log("useMediaPlayerStore.getState()", useMediaPlayerStore.getState());

  // useEffect(() => {
  //   if (!timerInfinity) return;
  //   if (!timerHoursDuration || !timerMinutesDuration) return;

  //   if (isPlaying) handleSetTimer();
  // }, [isPlaying, timerHoursDuration, timerMinutesDuration, timerInfinity]);

  return null;
};

export default MediaPlayerTimerManager;
