import { useState } from "react";
import Icons from "./icons/Icons";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import LockedModal from "components/LockedModal";
import { UserAccessStateEnum } from "context/userContext";
import { durationToMinutes } from "utils/time";
import { cn } from "libs/classMerger";
import type { Resource } from "types/__generated__/graphql";

interface LinkButtonComponentProps {
  type: "meditations" | "soundscapes";
  trackData: Resource;
  onHover?: (image: string) => void;
  onLeave?: () => void;
  onClick?: (image: string) => void;
  userAccessState: UserAccessStateEnum;
  containerClasses?: string;
  tileClasses?: string;
  bannerTile?: boolean;
  titleClasses?: string;
  showDuration?: boolean;
}

const MediaCardComponent = ({
  type,
  trackData,
  onHover,
  onLeave,
  onClick,
  userAccessState,
  containerClasses,
  tileClasses,
  bannerTile = true,
  titleClasses,
  showDuration = false,
}: LinkButtonComponentProps) => {
  const soundscape = type === "soundscapes";
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const track = useMediaPlayerStore((state) => state.track);
  const setTrack = useMediaPlayerStore((state) => state.setTrack);
  const setTimerInfinity = useMediaPlayerStore(
    (state) => state.setTimerInfinity
  );
  const setSoundscapeMode = useMediaPlayerUIStore(
    (state) => state.setSoundscapeMode
  );

  const { play, pause, playTrack } = useMediaPlayerHook();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handlePlayPause = async () => {
    if (userAccessState !== UserAccessStateEnum.Premium) {
      return setIsModalOpen(true);
    }

    setSoundscapeMode(soundscape);
    setTimerInfinity(soundscape);

    const isCurrentTrack = track?.id === trackData.id;
    if (isCurrentTrack) {
      isPlaying ? pause() : play();
    } else {
      setTrack(trackData);
      playTrack({
        trackToPlay: trackData,
        soundscapeMode: soundscape,
      });
    }
  };

  if (!trackData) return null;

  return (
    <>
      <div
        className={cn(
          "flex-col justify-start items-start gap-3 flex",
          containerClasses
        )}
        onMouseEnter={() => {
          if (onHover) onHover(trackData?.landscapeCoverAsset?.url ?? "");
        }}
        onMouseLeave={() => {
          if (onLeave) onLeave();
        }}
        onClick={() => {
          if (onClick) onClick(trackData.landscapeCoverAsset?.url ?? "");
        }}
        onKeyDown={() => { }}
      >
        <div
          className={cn(
            "w-40 h-40 flex-col justify-end items-start flex bg-cover bg-no-repeat group cursor-pointer",
            soundscape ? "rounded-full" : "rounded-2xl",
            tileClasses
          )}
          style={{
            backgroundImage: `url('${trackData.coverAsset?.url}')`,
          }}
          onClick={handlePlayPause}
          onKeyDown={() => { }}
        >
          <div className="h-10 w-10 p-2.5 mr-[8px] mb-[-10px] z-10 bg-[#0f131a] rounded-[128px] justify-start self-end items-center gap-2.5 opacity-0 group-hover:opacity-100 group-hover:-translate-y-4 transition-all duration-400 ease-in-out">
            {track?.id === trackData.id && track?.title === trackData.title ? (
              !isPlaying ? (
                <Icons name="play-filled" fill="white" height={20} width={20} />
              ) : (
                <Icons
                  name="pause-filled"
                  fill="white"
                  height={20}
                  width={20}
                />
              )
            ) : (
              <Icons name="play-filled" fill="white" height={20} width={20} />
            )}
          </div>
          {/* {imageOverlayText ? (
            <div className="w-full px-3 py-1 rounded-b-2xl bg-black/40 backdrop-blur-md justify-start items-start inline-flex">
              <div className="grow shrink basis-0 h-[15px] justify-start items-center flex">
                <div className="justify-start items-center gap-1.5 flex">
                  <div className="scale-75 relative">
                    <Icons
                      name="past-outlined"
                      height={24}
                      width={24}
                      fill="white"
                      className="pointer-events-none"
                    />
                  </div>
                  <div className="text-white text-[10px] body-2xs leading-[15px] tracking-wide">
                    {imageOverlayText}
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
        <div
          className={cn(
            "h-[62px] flex-col justify-start items-start gap-1 flex w-40",
            titleClasses
          )}
        >
          <div
            className={cn(
              "self-stretch text-white title-8 leading-[18px] tracking-tight text-ellipsis line-clamp-1",
              bannerTile ? "text-white" : "text-cool-grey-700"
            )}
          >
            {trackData.title}
          </div>
          <div className="text-[#b3b8c1] body-small leading-tight tracking-tight">
            {trackData.author?.name}
          </div>
          {trackData.mediaAsset?.duration && showDuration && (
            <div className="text-[#b3b8c1] caption-disclaimer leading-none tracking-wide hidden md:flex">
              {durationToMinutes(trackData.mediaAsset.duration)}
            </div>
          )}
        </div>
      </div>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </>
  );
};

export default MediaCardComponent;
