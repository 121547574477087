import {
  useGetLanguagesQuery,
  LanguageType,
  type GetLanguagesQueryVariables,
} from "types/__generated__/graphql";

function useLanguages(variables: GetLanguagesQueryVariables) {
  const { data, loading, error } = useGetLanguagesQuery({
    variables,
  });

  const languages = [
    { code: "fr", label: "Français" },
    { code: "en", label: "English" },
    { code: "es", label: "Español" },
    { code: "de", label: "Deutsch" },
    { code: "ru", label: "Русский" },
    { code: "it", label: "Italiano" },
    { code: "pt", label: "Português" },
    { code: "zh", label: "中文" },
  ];

  const languagesData =
    data?.languages?.edges?.map((edge) => {
      const language = edge.node;
      const matchedLanguage = languages.find(
        (lang) => lang.code === language?.iso2Code
      );
      return {
        ...language,
        label: matchedLanguage ? matchedLanguage.label : language?.iso2Code,
      };
    }) ?? [];

  return { data: languagesData, loading, error };
}

export default useLanguages;
