import { Tooltip } from "components/ui/tooltip";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import type { Resource } from "types/__generated__/graphql";

export const MediaCard = ({
  item,
  currentTrack,
  showOutcomes = false,
  isPlaying,
  handlePlayPause,
  thumbnailStyle,
}: {
  item: Resource;
  currentTrack: Resource;
  showOutcomes?: boolean;
  isPlaying: boolean;
  handlePlayPause: (track: Resource) => void;
  thumbnailStyle: "circle" | "square" | "circle-with-blurred-background";
}) => {
  return (
    <div
      key={item.id}
      role="button"
      tabIndex={0}
      onClick={() => handlePlayPause(item)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();

          handlePlayPause(item);
        }
      }}
      className="flex rounded-lg group"
    >
      <div
        className={cn(
          "relative max-w-[124px] overflow-hidden rounded-lg group",
          thumbnailStyle === "circle" && "rounded-full"
        )}
      >
        {/* <div
          className={cn(
            "absolute inset-0 bg-center rounded-lg",
            showOutcomes && "hidden"
          )}
          style={{
            backgroundImage: `url(${item.coverAsset?.url})`,
          }}
        /> */}
        <img
          src={item.coverAsset?.url}
          alt={item.title}
          className="object-cover aspect-square group-hover:scale-110 ease-in-out duration-500 transform transition-all"
        />
        {/* <div
          className={cn(
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contain bg-center rounded-full w-[124px] h-[124px]",
            showOutcomes && "rounded-none"
          )}
          style={{
            backgroundImage: `url(${item.coverAsset?.url})`,
          }}
        /> */}
      </div>
      <div className="flex gap-3 px-3 justify-between w-full items-center rounded-tr-lg rounded-br-lg">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <div className="title-8 text-cool-grey-700 data-[text-light]:text-white">
              {" "}
              {item.title}
            </div>
            <div className="caption-disclaimer text-cool-grey-500">
              {" "}
              {item.author?.name}
            </div>
          </div>
          {showOutcomes && (
            <div className="body-2-xs py-1 px-2 w-fit rounded-full bg-brown-90a border border-black-12a">
              {item.outcomes?.[0].name ?? ""}
            </div>
          )}
        </div>

        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">Play</p>}
        >
          <Button
            variant="ghost"
            size="roundedIcon"
            className="border border-black-12a shrink-0"
            onClick={() => handlePlayPause(item)}
          >
            {currentTrack?.id === item.id ? (
              !isPlaying ? (
                <Icons name="play-filled" height={20} width={20} />
              ) : (
                <Icons name="pause-filled" height={20} width={20} />
              )
            ) : (
              <Icons name="play-filled" height={20} width={20} />
            )}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
