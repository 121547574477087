import { create } from "zustand";

interface UIState {
  show: boolean;
  fullScreenMode: boolean;
  soundscapeMode: boolean;
  isTimerSet: boolean;
  immersionMode: boolean;
  setShow: (show: boolean) => void;
  setFullScreenMode: (fullScreen: boolean) => void;
  setSoundscapeMode: (mode: boolean) => void;
  setImmersionMode: (enabled: boolean) => void;
  setIsTimerSet: (isTimerSet: boolean) => void;
}

const useMediaPlayerUIStore = create<UIState>((set) => ({
  show: false,
  fullScreenMode: false,
  soundscapeMode: false,
  isTimerSet: true,
  immersionMode: false,
  setShow: (show) => set({ show }),
  setFullScreenMode: (fullScreenMode) => set({ fullScreenMode }),
  setSoundscapeMode: (mode) => set({ soundscapeMode: mode }),
  setImmersionMode: (enabled) => set({ immersionMode: enabled }),
  setIsTimerSet: (isTimerSet) => set({ isTimerSet }),
}));

export default useMediaPlayerUIStore;
