import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CarouselPlayerBanner } from "components/soundscapesAndMeditations/CarouselPlayerBanner";
import { CuratedCollections } from "components/soundscapesAndMeditations/CuratedCollections";
import { Collections } from "components/soundscapesAndMeditations/Collections";
import { CategoriesSection } from "../../components/soundscapesAndMeditations/CategoriesSection";
import { FeaturedCollectionSection } from "components/soundscapesAndMeditations/FeaturedCollection";
import { PopularInOrganisation } from "../../components/soundscapesAndMeditations/PopularInOrganisationSection";
import { SoundscapeMixerSection } from "./sections/SoundscapeMixerSection";
import useSoundscapesOfTheWeek from "hooks/useSoundscapesOfTheWeek";
import useChannelBySlug from "hooks/useChannelBySlug";
import useSoundscapePopularInTheOrg from "hooks/useSoundscapePopularInTheOrg";
import { CollectionHighlightStatusFilterEnum } from "types/__generated__/graphql";

export default function Soundscapes() {
  const exploreCuratedsRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const {
    data: soundscapesOfTheWeek,
    loading: fetchingSoundscapeOfTheWeek,
    error: soundscapeOfTheWeekFetchingError,
  } = useSoundscapesOfTheWeek();

  const {
    data: curratedCollectionsData,
    loading: fetchingCurratedCollections,
    error: curratedCollectionsFetchingError,
  } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelOne,
  });

  const { data: highlightedCollectionsData } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelTwo,
  });

  const { data: featuredCollections, loading: fetchingFeaturedCollections } =
    useChannelBySlug({
      slug: "b2b-soundscape-collections",
      highlightStatus: CollectionHighlightStatusFilterEnum.Featured,
    });

  const { data: popularInOrgSoundscapeData } = useSoundscapePopularInTheOrg();

  const {
    data: soundscapeCategoriesData,
    loading: fetchingSoundscapeCategories,
    error: soundscapeCategoriesFetchingError,
  } = useChannelBySlug({
    slug: "b2b-soundscape-categories",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <section className="flex flex-col gap-12 md:gap-16 lg:gap-16">
      <div className="pt-4 px-3">
        <CarouselPlayerBanner
          type="soundscapes"
          bannerTitle="Soundscapes of the week"
          data={soundscapesOfTheWeek}
          isFetching={fetchingSoundscapeOfTheWeek}
          fetchingError={soundscapeOfTheWeekFetchingError}
          handleScrollNavigationRef={exploreCuratedsRef}
        />
      </div>
      <div className="flex flex-col container px-4 gap-12 md:gap-16 lg:gap-16" style={{ maxWidth: "1800px" }}>
        <div
          ref={exploreCuratedsRef}
          className="flex flex-col gap-12 md:gap-16 lg:gap-16"
        >
          <CuratedCollections
            type="soundscapes"
            data={curratedCollectionsData}
            isFetching={fetchingCurratedCollections}
            fetchingError={curratedCollectionsFetchingError}
            handleViewCollectionNavigation={() =>
              navigate("/soundscapes/collections")
            }
          />
          {highlightedCollectionsData?.collections?.map((collection) => (
            <Collections
              key={collection?.id}
              type="soundscapes"
              collection={collection}
            />
          ))}
        </div>
        <FeaturedCollectionSection
          type="soundscapes"
          data={featuredCollections}
          loading={fetchingFeaturedCollections}
          handleViewCollectionNavigation={() =>
            navigate("/soundscapes/collections")
          }
        />
        <PopularInOrganisation data={popularInOrgSoundscapeData} />
        <SoundscapeMixerSection />
      </div>
      <CategoriesSection
        data={soundscapeCategoriesData}
        loading={fetchingSoundscapeCategories}
        error={soundscapeCategoriesFetchingError}
        handleSeeAllNavigation={() => navigate("/soundscapes/categories")}
        handleCategoryNavigation={(slug) =>
          navigate(`/soundscapes/categories/${slug}`)
        }
      />
    </section>
  );
}
