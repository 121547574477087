import { microsoftAuthUrl } from "config/authConfig";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { authLoginPopUp } from "utils/authHelper";

export function useMicrosoftLogin() {
  const { userAccessState } = useUserState();
  const navigate = useNavigate();

  async function handleLogin() {
    try {
      const accessToken = await authLoginPopUp(microsoftAuthUrl);

      if (!accessToken) return;

      const tokenData = JSON.parse(atob(accessToken.split('.')[1]));
      const userSub = tokenData.sub;

      console.log("User Access State:", userAccessState);
      const visitedCompanyForm = localStorage.getItem("visitedCompanyForm");
      const targetRoute = userAccessState !== UserAccessStateEnum.Premium && (!visitedCompanyForm || visitedCompanyForm !== userSub)
        ? "/companyForm"
        : "/";

      if (targetRoute === "/companyForm") {
        localStorage.setItem("visitedCompanyForm", userSub);
      }

      navigate(targetRoute);
      window.location.reload();
    } catch (error) {
      console.warn("Error during login:", error);
    }
  }

  return handleLogin;
}
