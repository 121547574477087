import { useUserState } from "context/userContext";
import {
  useGetRecommendedHarmonizedMeditationsQuery,
  type Resource,
  type GetRecommendedHarmonizedMeditationsQueryVariables,
} from "types/__generated__/graphql";

export default function useHarmonizedMeditation() {
  const { organizationData } = useUserState();

  const variables: GetRecommendedHarmonizedMeditationsQueryVariables = {
    first: 7,
    limit: "7",
    orgId: organizationData?.id.toString() ?? "",
  };

  const { data, loading, error, refetch } =
    useGetRecommendedHarmonizedMeditationsQuery({
      variables,
      fetchPolicy: "no-cache", // TODO:: There's caching issue with this query
    });

  const harmonizedMeditations =
    data?.recommendedB2bHarmonizedMeditation?.edges?.map(
      (edge) => edge?.node?.soundscape as Resource
    );

  return { data: harmonizedMeditations, loading, error, refetch };
}
