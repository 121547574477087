import { useState } from "react";
import { Button } from "components/Button";
import {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "components/Carousel";
import useScreenType from "hooks/useScreenType";
import { CollectionDetailModal } from "components/soundscapesAndMeditations/CollectionDetailModal";
import type { B2bChannel, B2bCollection } from "types/__generated__/graphql";

const card = (collection: B2bCollection) => {
  return (
    <div className="flex flex-col gap-3 w-[398px] lg:w-[411px]">
      <div className="w-[398px] lg:w-[411px] h-[232px] overflow-hidden rounded-md ">
        <img
          className="object-cover aspect-video rounded-md transition-all ease-in-out duration-500 hover:scale-105 "
          alt={collection.title ?? "Default Alt Text"}
          src={collection.coverAsset?.url ?? ""}
        />
      </div>
      <div className="flex flex-col gap-1 items-start">
        <div className="title-10 lg:hidden text-cool-grey-450">
          {collection.subtitle}
        </div>
        <div className="heading-7">{collection.title}</div>
        <div className="caption-disclaimer text-cool-grey-500 line-clamp-2 text-start">
          {collection.description}
        </div>
      </div>
    </div>
  );
};

export const CuratedCollections = ({
  type,
  data,
  isFetching,
  fetchingError,
  handleViewCollectionNavigation,
}: {
  type: "meditations" | "soundscapes";
  data: B2bChannel | null;
  isFetching: boolean;
  fetchingError: Error | undefined;
  handleViewCollectionNavigation: () => void;
}) => {
  const screenType = useScreenType();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const isMobile = screenType.isMobile || screenType.isTablet;

  // TODO:: Handle with skelly
  if (!data || isFetching || fetchingError) return null;

  return (
    <div className="flex flex-col gap-6 overflow-hidden">
      <div className="flex justify-between items-center">
        <div className="heading-6">Explore curated collections</div>
        <div className="flex gap-2 items-center">
          <Button variant="ghost" onClick={handleViewCollectionNavigation}>
            See all
          </Button>
          <div className="hidden md:flex lg:hidden items-start space-x-2 ">
            <CarouselPrevious
              onClick={() => {
                carouselApi?.scrollPrev();
              }}
            />
            <CarouselNext
              onClick={() => {
                carouselApi?.scrollNext();
              }}
            />
          </div>
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          active: isMobile,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <CarouselContent className="gap-2 md:gap-4 lg:gap-6 ml-0">
          {data.collections?.map((item) => (
            <CarouselItem
              key={item?.id}
              className="basis-auto pl-0 justify-center"
            >
              {item && (
                <CollectionDetailModal
                  type={type}
                  collection={item}
                  trigger={card(item)}
                />
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
