import { useShallow } from "zustand/shallow";
import { useCallback } from "react";
import { useMediaPlayerRefs } from "context/MediaPlayerRefsContext";
import useGetResourceById from "hooks/useGetResourceById";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaMixerStore from "stores/mediaPlayerMixerStore";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import type { Resource } from "types/__generated__/graphql";

export function useMediaPlayerHook() {
  const {
    track,
    isPlaying,
    masterVolume,
    trackVolume,
    setIsPlaying,
    setToggleMute,
    setTrack,
    resetMediaStateAll,
  } = useMediaPlayerStore(
    useShallow((state) => ({
      isPlaying: state.isPlaying,
      track: state.track,
      masterVolume: state.masterVolume,
      trackVolume: state.trackVolume,
      setIsPlaying: state.setIsPlaying,
      setToggleMute: state.setToggleMute,
      setTrack: state.setTrack,
      resetMediaStateAll: state.resetMediaStateAll,
    }))
  );

  const { mixerTrack, mixers, mixerVolume, setModifiedMixers, setMixerTrack } =
    useMediaMixerStore(
      useShallow((state) => ({
        mixerTrack: state.mixerTrack,
        mixers: state.mixers,
        mixerVolume: state.mixerVolume,
        setMixerTrack: state.setMixerTrack,
        setModifiedMixers: state.setModifiedMixers,
      }))
    );

  const {
    soundscapeMode,
    immersionMode,
    fullScreenMode,
    setShow,
    setSoundscapeMode,
    setImmersionMode,
    setIsTimerSet,
    setFullScreenMode,
  } = useMediaPlayerUIStore(
    useShallow((state) => ({
      soundscapeMode: state.soundscapeMode,
      immersionMode: state.immersionMode,
      fullScreenMode: state.fullScreenMode,
      setShow: state.setShow,
      setSoundscapeMode: state.setSoundscapeMode,
      setImmersionMode: state.setImmersionMode,
      setIsTimerSet: state.setIsTimerSet,
      setFullScreenMode: state.setFullScreenMode,
    }))
  );

  // ----- Refs -----
  const { audioPlayerRef, mixerAudioPlayerRef, videoPlayerRef } =
    useMediaPlayerRefs();

  const {
    fetchResource: fetchhSupportingMixerData,
    // loading: fetchingMixerTrack,
    // error: mixerError,
  } = useGetResourceById();

  const calculateEffectiveTrackVolume = () => {
    return (trackVolume / 100) * (masterVolume / 100);
  };

  const calculateEffectiveMixerVolume = () => {
    return (mixerVolume / 100) * (masterVolume / 100);
  };

  const waitForMediaReady = useCallback(
    async (mediaRef: React.RefObject<HTMLMediaElement>) => {
      return new Promise<void>((resolve, reject) => {
        const mediaElement = mediaRef.current;
        if (!mediaElement) {
          console.warn("Media element not found.");
          reject(new Error("Media element not found"));
          return;
        }

        const handleError = (e: Event) => {
          console.log("Media error occurred.");
          mediaElement.removeEventListener("canplay", handleCanPlay);
          mediaElement.removeEventListener("error", handleError);
          reject(
            new Error(`Media loading failed: ${(e as ErrorEvent).message}`)
          );
        };

        const handleCanPlay = () => {
          mediaElement.removeEventListener("canplay", handleCanPlay);
          mediaElement.removeEventListener("error", handleError);
          resolve();
        };

        mediaElement.addEventListener("canplay", handleCanPlay);
        mediaElement.addEventListener("error", handleError);

        // If media is already ready, resolve immediately
        if (mediaElement.readyState >= 3) {
          handleCanPlay();
        }
      });
    },
    []
  );

  const play = useCallback(async () => {
    try {
      await waitForMediaReady(audioPlayerRef);
      await audioPlayerRef.current?.play();
      await videoPlayerRef.current?.play();

      if (!soundscapeMode) {
        await mixerAudioPlayerRef.current?.play();
      }

      setIsPlaying(true);
      setShow(true);
    } catch (error) {
      console.error("Error playing media:", error);
    }
  }, [soundscapeMode, waitForMediaReady]);

  const pause = useCallback(async () => {
    try {
      audioPlayerRef.current?.pause();

      mixerAudioPlayerRef.current?.pause();

      if (!soundscapeMode) {
        videoPlayerRef.current?.pause();
      }

      setIsPlaying(false);
    } catch (error) {
      console.error("Error pausing media:", error);
    }
  }, []);

  const playTrack = useCallback(
    async ({
      trackToPlay,
      soundscapeMode = false,
    }: {
      trackToPlay: Resource;
      soundscapeMode?: boolean;
    }) => {
      try {
        const audioElement = audioPlayerRef.current;
        const mixerElement = mixerAudioPlayerRef.current;

        if (!audioElement) {
          console.warn("Audio element not available.");
          return;
        }

        if (audioElement) audioElement.pause();
        if (mixerElement) mixerElement.pause();

        audioElement.src = trackToPlay.mediaAsset?.url || "";

        setIsPlaying(true);

        // TODO: Fix this
        // if (
        //   !soundscapeMode &&
        //   trackToPlay.subtype !== "vocal_with_background"
        // ) {
        //   if (!trackToPlay.supportingResource?.id) return;

        //   const { data, error } = await fetchhSupportingMixerData({
        //     variables: { id: trackToPlay.supportingResource?.id },
        //   });

        //   if (data && !error) {
        //     if (mixers) {
        //       const combineMixers = [
        //         data.standaloneResource,
        //         ...mixers
        //           .slice(1)
        //           .filter((mixer) => mixer.id !== mixerTrack?.id),
        //         mixers[0],
        //       ];

        //       setModifiedMixers(combineMixers);
        //     }

        //     await playMixerTrack(data.standaloneResource);
        //   }
        // }

        audioElement.load();
        await waitForMediaReady(audioPlayerRef);
        await audioElement.play();
        setShow(true);
      } catch (error) {
        console.error("Error playing track:", error);
      }
    },
    [soundscapeMode, waitForMediaReady, mixers, track]
  );

  const playMixerTrack = useCallback(
    async (mixerToPlay: Resource) => {
      if (soundscapeMode) return;

      const mixerElement = mixerAudioPlayerRef.current;

      if (!mixerElement) {
        console.warn("Mixer audio element not available.");
        return;
      }

      try {
        if (mixerToPlay.title === "No background sound") {
          mixerElement.pause();
          return;
        }

        mixerElement.pause();
        mixerElement.src = mixerToPlay.mediaAsset?.url || "";
        mixerElement.load();

        if (!useMediaPlayerStore.getState().isPlaying) return;

        await waitForMediaReady(mixerAudioPlayerRef);

        await mixerElement.play();
      } catch (error) {
        console.error("Error playing mixer track:", error);
      }
    },
    [mixerAudioPlayerRef, waitForMediaReady]
  );

  const toggleFullScreen = useCallback(() => {
    if (!fullScreenMode) {
      setImmersionMode(true);
    } else {
      setImmersionMode(!immersionMode);
    }

    setFullScreenMode(!fullScreenMode);
  }, [fullScreenMode, setFullScreenMode, setImmersionMode]);

  const toggleImmersion = useCallback(() => {
    console.log("Toggling immersion mode", immersionMode);
    setImmersionMode(!immersionMode);
    // if (immersionMode) {
    //   videoPlayerRef.current?.play();
    // } else {
    //   videoPlayerRef.current?.pause();
    // }
  }, [immersionMode, setImmersionMode]);

  const handleRewind = useCallback(() => {
    const SECONDS_TO_REWIND = 15;
    const audioElement = audioPlayerRef.current;
    const mixerAudioPlayer = mixerAudioPlayerRef.current;

    if (
      audioElement &&
      Number.isFinite(audioElement.currentTime) &&
      Number.isFinite(audioElement.duration)
    ) {
      audioElement.currentTime = Math.max(
        audioElement.currentTime - SECONDS_TO_REWIND,
        0
      );
    }
    if (
      mixerAudioPlayer &&
      Number.isFinite(mixerAudioPlayer.currentTime) &&
      Number.isFinite(mixerAudioPlayer.duration)
    ) {
      mixerAudioPlayer.currentTime = Math.max(
        mixerAudioPlayer.currentTime - SECONDS_TO_REWIND,
        0
      );
    }
  }, [immersionMode]);

  const handleForward = useCallback(() => {
    const SECONDS_TO_FORWARD = 15;
    const audioElement = audioPlayerRef.current;
    const mixerAudioPlayer = mixerAudioPlayerRef.current;

    if (
      audioElement &&
      Number.isFinite(audioElement.currentTime) &&
      Number.isFinite(audioElement.duration)
    ) {
      audioElement.currentTime = Math.min(
        audioElement.currentTime + SECONDS_TO_FORWARD,
        audioElement.duration
      );
    }
    if (
      mixerAudioPlayer &&
      Number.isFinite(mixerAudioPlayer.currentTime) &&
      Number.isFinite(mixerAudioPlayer.duration)
    ) {
      mixerAudioPlayer.currentTime = Math.min(
        mixerAudioPlayer.currentTime + SECONDS_TO_FORWARD,
        mixerAudioPlayer.duration
      );
    }
  }, []);

  const onPlayPause = useCallback(() => {
    isPlaying ? pause() : play();
  }, [isPlaying]);

  const load = () => {
    audioPlayerRef.current?.load();
    mixerAudioPlayerRef.current?.load();
    setIsPlaying(false);
  };

  const close = () => {
    audioPlayerRef.current?.pause();
    mixerAudioPlayerRef.current?.pause();
    videoPlayerRef.current?.pause();

    // clear all state
    resetMediaStateAll();
    setShow(false);
    setIsTimerSet(false);
    setImmersionMode(false);
    setFullScreenMode(false);
    setSoundscapeMode(false);
    setMixerTrack(null);
  };

  const toggleMute = (mute: boolean) => {
    setToggleMute(mute);

    if (audioPlayerRef.current) {
      audioPlayerRef.current.muted = mute;
    }

    if (track?.subtype === "vocal") {
      if (mixerAudioPlayerRef.current) {
        mixerAudioPlayerRef.current.muted = mute;
      }
    }
  };

  const handleVolumeChange = useCallback(
    (newTrackVolume: number) => {
      // For the main track volume slider
      // update trackVolume in context
      if (audioPlayerRef.current) {
        audioPlayerRef.current.volume =
          (newTrackVolume / 100) * (masterVolume / 100);
      }
    },
    [audioPlayerRef, masterVolume]
  );

  const handleMixerVolumeChange = useCallback(
    (newMixerVolume: number) => {
      // For the mixer volume slider
      if (mixerAudioPlayerRef.current) {
        mixerAudioPlayerRef.current.volume =
          (newMixerVolume / 100) * (masterVolume / 100);
      }
    },
    [mixerAudioPlayerRef, masterVolume]
  );

  const handleMasterVolumeChange = useCallback(
    (newMasterVolume: number) => {
      // Apply to both track and mixer
      if (audioPlayerRef.current) {
        audioPlayerRef.current.volume =
          (trackVolume / 100) * (newMasterVolume / 100);
      }
      if (mixerAudioPlayerRef.current) {
        mixerAudioPlayerRef.current.volume =
          (mixerVolume / 100) * (newMasterVolume / 100);
      }
    },
    [trackVolume, mixerVolume, audioPlayerRef, mixerAudioPlayerRef]
  );

  return {
    play,
    pause,
    onPlayPause,
    playTrack,
    playMixerTrack,
    toggleFullScreen,
    toggleImmersion,
    handleRewind,
    handleForward,
    load,
    close,
    toggleMute,
    calculateEffectiveTrackVolume,
    calculateEffectiveMixerVolume,
    handleVolumeChange,
    handleMixerVolumeChange,
    handleMasterVolumeChange,
  };
}
