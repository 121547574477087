import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import QuestsSkeleton from "components/loading/QuestsSkeleton";
import { QuestCard } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useTrendingGloballyQuests from "hooks/useTrendingGloballyQuests";
import { useState } from "react";
import useScreenType from "hooks/useScreenType";

export function RecommendedForYou() {
  const { userAccessState } = useUserState();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const screenType = useScreenType();

  const { data: quests, loading: fetchingTrendingGloballyQuestsData } =
    useTrendingGloballyQuests({
      first: 10,
    });

  if (!quests || fetchingTrendingGloballyQuestsData) return <QuestsSkeleton />;

  if (!quests?.length)
    return <QuestsSkeleton infoHeader="No Quest available" />;

  const filteredQuests = screenType.isLargeDesktop
    ? quests
    : quests.slice(0, 4);

  return (
    <section className="bg-[#F9F8F4]">
      <div
        className="flex flex-col gap-y-0 w-full px-5 py-6 gap-6 container mx-auto"
        style={{ maxWidth: "1800px" }}
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start gap-0.5">
            <div className="heading-7">
              {userAccessState === UserAccessStateEnum.Premium
                ? "Recommended"
                : "Trending globally"}
            </div>
            {userAccessState === UserAccessStateEnum.Premium && (
              <div className="hidden lg:flex text-cool-grey-450">
                Quests based on your interest
              </div>
            )}
          </div>
          {(screenType.isMobile || screenType.isTablet) && (
            <div className="flex items-start space-x-2">
              <CarouselPrevious
                onClick={() => {
                  carouselApi?.scrollPrev();
                }}
              />
              <CarouselNext
                onClick={() => {
                  carouselApi?.scrollNext();
                }}
              />
            </div>
          )}
        </div>

        <Carousel
          opts={{
            align: "start",
            loop: false,
          }}
          orientation="horizontal"
          className="w-full"
          setApi={setCarouselApi}
        >
          <CarouselContent>
            {filteredQuests.map((quest) => (
              <CarouselItem
                key={quest?.id}
                className="py-4 lg:py-6 w-sm md:w-md lg:w-lg xl:w-xl basis-full md:basis-1/2 lg:basis-1/4 xl:basis-1/5"
              >
                <QuestCard
                  quest={quest}
                  canHover={
                    (screenType.isDesktop || screenType.isLargeDesktop) &&
                    userAccessState === UserAccessStateEnum.Premium
                  }
                  userAccessState={userAccessState}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </section>
  );
}
