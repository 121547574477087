import { useState } from "react";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import MediaCardComponent from "components/MediaCardComponent";
import { cn } from "libs/classMerger";
import useScreenType from "hooks/useScreenType";
import { useUserState } from "context/userContext";
import { ViewCollectionButton } from "./ViewCollectionButton";
import type { B2bChannel } from "types/__generated__/graphql";

const HeaderDetails = ({ data }: { data: B2bChannel }) => {
  return (
    <div>
      <div className="title-10 text-teal-200">FEATURED COLLECTION</div>
      <div className="heading-2 text-white">
        {data.collections?.[0]?.title ?? ""}
      </div>
      <div className="body-small text-cool-grey-200">
        {data.collections?.[0]?.description ?? ""}
      </div>
    </div>
  );
};

export const FeaturedCollectionSection = ({
  type,
  data,
  loading,
  handleViewCollectionNavigation,
}: {
  type: "meditations" | "soundscapes";
  data: B2bChannel | null;
  loading?: boolean;
  handleViewCollectionNavigation: () => void;
}) => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [currentItem, setCurrentItem] = useState<number>(0);
  const screenType = useScreenType();
  const { userAccessState } = useUserState();

  const collectionResources = data?.collections?.[0]?.collectionResources ?? [];

  function nextPreviousArrowHandler(value: number) {
    const newIndex = Math.max(
      0,
      Math.min(currentItem + value, collectionResources.slice(0, 6).length - 1)
    ); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselApi?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselApi?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  function CarouselSection(className?: string) {
    return (
      <Carousel
        opts={{
          align: "start",
          active: screenType.isMobile || screenType.isTablet,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <CarouselContent
          className={cn("gap-2 md:gap-4 lg:gap-6 ml-0 ", className)}
        >
          {collectionResources.slice(0, 6).map((item) => (
            <CarouselItem
              key={item.id}
              className="basis-auto pl-0 justify-center"
            >
              <MediaCardComponent
                key={item.id}
                // @ts-expect-error TODO:: Update the type for the track component
                trackData={item}
                isSoundScapeOnly={true}
                userAccessState={userAccessState}
                containerClasses="w-[195px] md:w-[202px] lg:w-[193px]"
                tileClasses="w-[195px] md:w-[202px] lg:w-[193px] h-[195px] md:h-[202px] lg:h-[193px]"
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    );
  }

  // TODO:: Use skelly loader
  if (!data || loading) return <></>;

  return (
    <section>
      {screenType.isDesktop || screenType.isLargeDesktop ? (
        <div
          className="flex relative items-end justify-between rounded-3xl w-auto h-auto px-10 py-12 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${
              data.collections?.[0]?.landscapeCoverAsset?.url ?? ""
            })`,
          }}
        >
          <div className="absolute inset-x-0 bottom-0 h-[75%] bg-gradient-to-t from-black to-transparent rounded-3xl" />
          <div className="absolute inset-y-0 right-0 w-[75%] bg-gradient-to-l from-black to-transparent rounded-3xl" />
          <div className="flex flex-col gap-4 z-10">
            <div className="flex flex-col w-[480px] gap-1">
              <HeaderDetails data={data} />
            </div>
            <ViewCollectionButton
              type={type}
              data={data}
              navigate={handleViewCollectionNavigation}
            />
          </div>
          <div className="flex w-[627px]">{CarouselSection("flex-wrap")}</div>
        </div>
      ) : (
        <div className="flex">
          <div className="flex flex-col w-[430px] md:w-full md:min-w-[768px]">
            <div
              className="relative  w-full h-[432px] bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${
                  data.collections?.[0]?.landscapeCoverAsset?.url ?? ""
                })`,
              }}
            >
              <div className="absolute inset-x-0 bottom-0 h-[75%] bg-gradient-to-t from-black to-transparent " />
            </div>
            <div className="flex flex-col bg-black px-6 pb-8 gap-10">
              <div className="flex flex-col gap-6 ">
                <div className="flex flex-col gap-1">
                  <HeaderDetails data={data} />
                </div>
                <div className="flex flex-col gap-4 ">
                  <div className="hidden md:flex lg:hidden items-start space-x-2 justify-end z-10">
                    <CarouselPrevious
                      disabled={currentItem === 0}
                      onClick={() => {
                        nextPreviousArrowHandler(-1);
                      }}
                      fillColor="#DFE1E5"
                    />
                    <CarouselNext
                      disabled={
                        currentItem ===
                        collectionResources.slice(0, 6).length - 1
                      }
                      onClick={() => {
                        nextPreviousArrowHandler(1);
                      }}
                      fillColor="#DFE1E5"
                    />
                  </div>
                  {CarouselSection()}
                </div>
              </div>
              <ViewCollectionButton
                type={type}
                data={data}
                navigate={handleViewCollectionNavigation}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
