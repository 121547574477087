// src/components/VideoPlayer/MVUniversalPlayerWrapper.js
import { h, defineComponent } from "vue";
import MVUniversalPlayer, {
  MVAudioPlayer,
  MVAudioResource,
  MVVideoPlayer,
  MVVideoResource,
} from "@mindvalley/mv-universal-player";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/ogg",
];

export default defineComponent({
  name: "MVUniversalPlayerWrapper",
  components: {
    MVUniversalPlayer,
    MVAudioPlayer,
    MVAudioResource,
    MVVideoPlayer,
    MVVideoResource,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatSources(localSources = []) {
      return (localSources || [])
        .filter((source: any) =>
          ALLOWED_MIME_TYPES.includes(source.contentType)
        )
        .map((source: any) => ({
          type: source.contentType,
          src: source.url,
        }));
    },
  },
  render() {
    return h("div", { class: "mv-universal-player" }, [
      h(MVVideoPlayer, {}, [
        h(MVVideoResource, {
          id: this.media.mediaAsset.id,
          sources: this.formatSources(this.media.mediaAsset.renditions),
          posterUrl: this.media.coverAsset.url,
          duration: this.media.mediaAsset.duration,
          markers: this.media.mediaAsset.markers,
          muted: this.muted,
          autoplay: this.autoplay,
        }),
      ]),
    ]);
  },
});
