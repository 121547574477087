// stores/mediaPlayerStore.ts
import { create } from "zustand";
import type { Time } from "/@types/player";
import type { Resource } from "types/__generated__/graphql";

interface MediaPlayerState {
  // Playback States
  isPlaying: boolean;
  masterVolume: number;
  trackVolume: number;
  isMuted: boolean;
  track: Resource | null;
  time: Time;

  // Timer States
  timerInfinity: boolean;
  timerHoursDuration: number;
  timerMinutesDuration: number;
  timerActive: boolean;

  // Timer Setters
  setTimerInfinity: (infinity: boolean) => void;
  setTimerHoursDuration: (hours: number) => void;
  setTimerMinutesDuration: (minutes: number) => void;
  setTimerActive: (active: boolean) => void;
  resetTimer: () => void;

  setIsPlaying: (isPlaying: boolean) => void;
  setMasterVolume: (volume: number) => void;
  setTrackVolume: (volume: number) => void;
  setTrack: (track: Resource | null) => void;
  setTime: (time: Time) => void;
  setToggleMute: (toggle: boolean) => void;

  resetMediaStateAll: () => void;
}

const useMediaPlayerStore = create<MediaPlayerState>((set) => ({
  // Playback States
  isPlaying: false,
  masterVolume: 100,
  trackVolume: 50,
  isMuted: false,
  track: null,
  time: {
    progress: 0,
    currentTime: { second: 0, minute: 0 },
    totalTime: { second: 0, minute: 0 },
  },

  // Timer States
  timerInfinity: true,
  timerHoursDuration: 0,
  timerMinutesDuration: 0,
  timerActive: false,

  // Timer Setters
  setTimerInfinity: (infinity) => set({ timerInfinity: infinity }),
  setTimerHoursDuration: (hours) => set({ timerHoursDuration: hours }),
  setTimerMinutesDuration: (minutes) => set({ timerMinutesDuration: minutes }),
  setTimerActive: (active) => set({ timerActive: active }),
  resetTimer: () =>
    set({
      timerInfinity: true,
      timerHoursDuration: 0,
      timerMinutesDuration: 0,
    }),

  setIsPlaying: (isPlaying) => set({ isPlaying }),
  setMasterVolume: (volume) => set({ masterVolume: volume }),
  setTrackVolume: (volume) => set({ trackVolume: volume }),
  setTrack: (track) => set({ track }),
  setTime: (time) => set({ time }),
  setToggleMute: (toggle) => set({ isMuted: toggle }),

  resetMediaStateAll: () =>
    set({
      isPlaying: false,
      track: null,
      time: {
        progress: 0,
        currentTime: { second: 0, minute: 0 },
        totalTime: { second: 0, minute: 0 },
      },
      timerInfinity: true,
      timerHoursDuration: 0,
      timerMinutesDuration: 0,
      timerActive: false,
    }),
}));

export default useMediaPlayerStore;
