import React, { useEffect, useRef } from "react";
import { createApp } from "vue";
import { ENV_ENUMS } from "config/teamsConfig";
import MVUniversalPlayerWrapper from "./MVUniversalPlayerWrapper";
import { svgSpriteDirectivePlugin } from "vue-svg-sprite";
import MVUniversalPlayer from "@mindvalley/mv-universal-player";
import svgSprite from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";
import "./videoPlayer.css";

function MVUniversalPlayerReact({ media, muted, autoplay }) {
  const containerRef = useRef(null);
  const vueAppRef = useRef(null);
  const iconPath =
    process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
      ? ""
      : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

  useEffect(() => {
    if (!containerRef.current) return;

    // Create the Vue app

    const app = createApp(MVUniversalPlayerWrapper, { media, muted, autoplay });
    app.use(MVUniversalPlayer);
    app.use(svgSpriteDirectivePlugin, { url: `${iconPath}/${svgSprite}` });
    app.mount(containerRef.current);

    vueAppRef.current = app;

    return () => {
      // Clean up the Vue instance on unmount
      if (vueAppRef.current) {
        vueAppRef.current.unmount();
      }
    };
  }, [media, muted, autoplay]);

  return <div ref={containerRef} className="mv-universal-player-container" />;
}

// 1) Wrap your function component in React.memo
export default React.memo(MVUniversalPlayerReact);
