import { Button } from "components/Button";
import { CarouselNext, CarouselPrevious } from "components/Carousel";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import useScreenType from "hooks/useScreenType";
import type { B2bQuest } from "/types/__generated__/graphql";

export function ContinueLearningNavigation({
  courses,
  currentItem,
  nextPerviousArrowHandler,
}: {
  courses: B2bQuest[];
  currentItem: number;
  nextPerviousArrowHandler: (value: number) => void;
}) {
  const hasMultipleCourses = courses.length > 1;
  const hasSingleCourse = courses.length === 1;
  const screenType = useScreenType();

  return (
    <div
      className={cn(
        "flex justify-between items-start gap-5 md:gap-20",
        hasMultipleCourses ? "lg:pt-16" : ""
      )}
    >
      <div
        className={`flex flex-col gap-3 max-w-[${
          screenType.isMobile ? "280px" : "480px"
        }]`}
      >
        <div className="heading-4">Continue where you left off</div>
        {courses.length > 1 && (
          <>
            <div className="text-sm body-small">
              We know life gets busy, but revisiting your library helps maintain
              progress and keeps you motivated. Dive deeper into the skills
              you're building and unlock new learning opportunities within your
              existing programs.
            </div>
            <Link to={NavigationEnum.Library} className="w-full md:w-fit">
              <Button
                variant="ghost"
                className="gap-2 w-fit md:w-auto bg-black-4a hover:bg-black-6a text-white"
              >
                <div className="button-text-medium text-cool-grey-700">
                  Go to my library
                </div>
                <Icons
                  name="arrow-right-filled"
                  width={14}
                  height={14}
                  fill="black"
                />
              </Button>
            </Link>
          </>
        )}
        {hasSingleCourse && (
          <>
            <div className="text-sm body-small">
              While we encourage exploration, focusing on a few key programs at
              a time allows for richer learning experiences. Master one skill
              before moving on to the next. When you're ready to expand your
              learning horizons, our programs page is your gateway to endless
              potential!
            </div>
            <Link to={NavigationEnum.Programs}>
              <Button variant={"neutral"} className="gap-2 w-full md:w-auto">
                <div className="button-text-medium">Discover more</div>
              </Button>
            </Link>
          </>
        )}
      </div>

      {hasMultipleCourses && (
        <div className="flex gap-2">
          <CarouselPrevious
            type="button"
            disabled={currentItem === 0}
            onClick={() => nextPerviousArrowHandler(-1)}
          >
            previous
          </CarouselPrevious>
          <CarouselNext
            type="button"
            disabled={currentItem === courses.length - 1}
            onClick={() => nextPerviousArrowHandler(1)}
          >
            next
          </CarouselNext>
        </div>
      )}
    </div>
  );
}
