import { Button } from "components/Button";
import { useState } from "react";
import {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "components/Carousel";
import MediaCardComponent from "components/MediaCardComponent";
import { CollectionDetailModal } from "components/soundscapesAndMeditations/CollectionDetailModal";
import { useUserState } from "context/userContext";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import type { B2bCollection } from "types/__generated__/graphql";

export const Collections = ({
  type,
  collection,
  isSoundscape = true,
}: {
  type: "meditations" | "soundscapes";
  collection: B2bCollection;
  isSoundscape?: boolean;
}) => {
  const screenType = useScreenType();

  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [currentItem, setCurrentItem] = useState<number>(0);
  const { userAccessState } = useUserState();
  function nextPreviousArrowHandler(value: number) {
    const newIndex = Math.max(
      0,
      Math.min(currentItem + value, collection.collectionResources.length - 1)
    ); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselApi?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselApi?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  const isMobile = screenType.isMobile || screenType.isTablet;

  return (
    <div
      className="flex flex-col gap-6 overflow-hidden"
      id={collection.title ?? undefined}
    >
      <div className="flex justify-between items-center">
        <div className="heading-6">{collection.title}</div>
        <div className="flex gap-2 items-center">
          <CollectionDetailModal
            type={type}
            collection={collection}
            trigger={
              <>
                <Button variant={"ghost"} className="hidden lg:flex">
                  View collection
                </Button>
                <Button variant={"ghost"} className="lg:hidden ">
                  View
                </Button>
              </>
            }
          />
          <div className="hidden md:flex lg:hidden items-start space-x-2 ">
            <CarouselPrevious
              disabled={currentItem === 0}
              onClick={() => {
                nextPreviousArrowHandler(-1);
              }}
            />
            <CarouselNext
              disabled={
                currentItem === collection.collectionResources.length - 1
              }
              onClick={() => {
                nextPreviousArrowHandler(1);
              }}
            />
          </div>
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          active: isMobile,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <CarouselContent
          className={cn(
            "ml-0",
            isMobile
              ? "gap-2 md:gap-4 lg:gap-6"
              : "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6"
          )}
        >
          {collection.collectionResources.slice(0, 6).map((item) => (
            <CarouselItem
              key={item.id + (item.title ?? "")}
              className="basis-auto pl-0 justify-center"
            >
              <MediaCardComponent
                // @ts-expect-error
                trackData={item}
                type={type}
                containerClasses="w-full"
                tileClasses="size-full aspect-square"
                // containerClasses="flex-row lg:flex-col items-center md:justify-center w-full"
                // tileClasses="lg:size-full aspect-square rounded-full"
                // titleClasses="h-full self-center w-full"
                bannerTile={false}
                userAccessState={userAccessState}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
