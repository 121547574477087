import { app, pages } from "@microsoft/teams-js";
import { useEffect } from "react";
import { NavigationEnum } from "router/navigationType";

export default function Configure() {
  useEffect(() => {
    app.initialize().then(() => {
      app.notifySuccess();

      pages.config.registerOnSaveHandler((saveEvent) => {
        pages.config.setConfig({
          entityId: "workway",
          contentUrl: `${window.location.origin}${NavigationEnum.AppInMeeting}
          suggestedDisplayName: "Workway"`,
          websiteUrl: `${window.location.origin}${NavigationEnum.AppInMeeting}`,
        });

        saveEvent.notifySuccess();
      });

      pages.config.setValidityState(true);
    });
  }, []);

  return <div className="absolute inset-y-1/2 translate-x-1/2 right-1/2">Add Workway</div>;
}
