import type React from "react";

interface ErrorOverlayProps {
  error: string | null;
  onRetry: () => void;
}

export const ErrorOverlay = ({ error, onRetry }: ErrorOverlayProps) => {
  if (!error) return null;

  return (
    <div
      className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-20"
      role="alert"
    >
      <p className="text-white text-lg mb-4">{error}</p>
      <button
        type="button"
        onClick={onRetry}
        className="px-4 py-2 bg-red-500 text-white rounded focus:outline-none focus:ring-2 focus:ring-red-600"
        aria-label="Retry loading video"
      >
        Retry
      </button>
    </div>
  );
};
