// @ts-ignore
import { debounce } from "lodash";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from "react";
import "./Programs.css";
import useCategories from "hooks/useCategories";
import useLanguages from "hooks/useLanguages";
import useQuest from "hooks/useQuest";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import { useLocation } from "react-router-dom";
import { QuestCategoryEnum } from "types/interfaces";
import type { ICategoryNode } from "types/interfaces";
import { QuestsCategorySection } from "./sections/QuestsCategorySection";
import { ProgramsHeroContainer } from "./components/ProgramsHeroContainer";
import { Button } from "components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/Dropdown";
import Icons from "components/icons/Icons";
import { LanguageType } from "types/__generated__/graphql";

export default function Programs() {
  const location = useLocation();
  const categoryView = useRef<HTMLDivElement>(null);
  const screenType = useScreenType();

  const [selectedTab, setSelectedTab] = useState<QuestCategoryEnum>(
    QuestCategoryEnum.Career
  );
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState("en");

  const {
    data: nonEnglishQuestsData,
    loading: fetchingNonEnglishQuestsData,
    fetchMoreQuests: fetchMoreNonEnglishQuests,
    pageInfo: nonEnglishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: selectedLang,
    },
    skippable: selectedLang === "en",
  });

  const {
    data: englishQuestsData,
    loading: fetchingEnglishQuestsData,
    fetchMoreQuests: fetchMoreEnglishQuests,
    pageInfo: englishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: "en",
    },
  });

  const { categoriesData, loading: categoryDataLoading } = useCategories();
  const { data: languages } = useLanguages({
    first: 20,
    type: LanguageType.Preferred,
  });

  const category = useMemo(
    () => categoriesData.find((cat) => cat.id === selectedTab),
    [categoriesData, selectedTab]
  );

  const bannerImage = useMemo(() => {
    return category?.assets?.banners[
      screenType.isDesktop
        ? "desktop"
        : screenType.isMobile
        ? "mobile"
        : "tablet"
    ];
  }, [category, screenType]);

  const onCategorySelect = useCallback(
    debounce((category: ICategoryNode) => {
      setSelectedTab(category.id as QuestCategoryEnum);
      categoryView.current?.scrollIntoView({ behavior: "smooth" });
    }, 300),
    []
  );

  const handleSelectLanguage = (code: string) => {
    setSelectedLang(code);
    if (code !== selectedLang) setIsLanguageMenuOpen(false);
  };

  const sortedLanguages = useMemo(() => {
    const selected = languages.find((lang) => lang.iso2Code === selectedLang);
    const others = languages.filter((lang) => lang.iso2Code !== selectedLang);
    return selected ? [selected, ...others] : languages;
  }, [selectedLang, languages]);

  useEffect(() => {
    if (location.state?.subPageId) {
      setSelectedTab(location.state.subPageId as QuestCategoryEnum);
    }
  }, [location]);

  return (
    <div
      className="flex flex-col pt-4 container px-5"
      style={{ maxWidth: "none" }}
    >
      <ProgramsHeroContainer
        categoriesData={categoriesData}
        onCategoryClick={onCategorySelect}
        loading={categoryDataLoading}
      />

      <div className="flex items-center border-y border-cool-grey-200 border-md gap-9 sticky top-0 bg-white z-40 w-full">
        <div
          className="flex w-full gap-9"
          data-testid="category-sticky-tabs-section"
        >
          {categoriesData?.map((programCategory: ICategoryNode) => (
            <div
              key={programCategory.id}
              className="flex flex-col items-center"
            >
              <Button
                key={programCategory.id}
                variant="inherit"
                onClick={() =>
                  setSelectedTab(programCategory.id as QuestCategoryEnum)
                }
                data-selected={programCategory.id === category?.id}
                className={cn(
                  "flex h-full py-5 px-0 hover:border-b-2 border-cool-grey-300",
                  "data-[selected=true]:border-b-2 data-[selected=true]:border-red-300",
                  programCategory.name === "Career" && "text-blue-500",
                  programCategory.name === "Mindset" && "text-teal-500",
                  programCategory.name === "Collaboration" && "text-purple-500",
                  programCategory.name === "Soul" && "text-orange-500",
                  programCategory.name === "Health" && "text-green-500",
                  programCategory.name === "Intrapreneurship" &&
                    "text-yellow-500"
                )}
              >
                <span className="heading-8 opacity-80">
                  {programCategory.name.toLowerCase()}
                </span>
              </Button>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-end gap-x-2 w-4/12">
          <div className="text-cool-grey-400 heading-9">BY LANGUAGE</div>
          <div>
            <DropdownMenu
              open={isLanguageMenuOpen}
              onOpenChange={setIsLanguageMenuOpen}
            >
              <DropdownMenuTrigger asChild>
                <Button
                  asChild
                  variant="dropdown"
                  className="p-1.5 rounded-lg bg-brown-50 cursor-pointer hover:bg-brown-100 w-[130px] border-none"
                >
                  <div className="flex justify-between items-center px-1.5 py-1 !text-cool-grey-600">
                    <div className="!title-8">
                      {
                        languages.find((lang) => lang.iso2Code === selectedLang)
                          ?.label
                      }
                    </div>
                    <Icons name="chevron-down-filled" height={20} width={20} />
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                sideOffset={-40}
                className="rounded-lg shadow-lg bg-brown-50 p-2 space-y-1.5 w-[280px] border-none"
              >
                {sortedLanguages.map((language, idx) => (
                  <Fragment key={language.iso2Code}>
                    {idx === 1 && <div className="h-px w-full bg-black-8a" />}
                    <DropdownMenuItem
                      data-selected={language.iso2Code === selectedLang}
                      className="p-2 flex justify-between items-center self-stretch rounded-lg cursor-pointer hover:bg-brown-100"
                      onSelect={(event) => {
                        event.preventDefault();
                        handleSelectLanguage(language.iso2Code ?? "");
                      }}
                    >
                      <p className="text-cool-grey-600 title-8">
                        {language.label}
                      </p>
                      {language.iso2Code === selectedLang && (
                        <Icons
                          name="check-filled"
                          height={20}
                          width={20}
                          fill="currentColor"
                        />
                      )}
                    </DropdownMenuItem>
                  </Fragment>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <div className="mt-8" ref={categoryView}>
        <QuestsCategorySection
          category={category}
          bannerImage={bannerImage}
          selectedLanguageLabel={
            languages.find((lang) => lang.iso2Code === selectedLang)?.label
          }
          nonEnglishQuestsData={nonEnglishQuestsData}
          englishQuestsData={englishQuestsData}
          loading={fetchingEnglishQuestsData || fetchingNonEnglishQuestsData}
          fetchMoreQuests={
            selectedLang === "en"
              ? fetchMoreEnglishQuests
              : fetchMoreNonEnglishQuests
          }
          pageInfo={
            selectedLang === "en" ? englishPageInfo : nonEnglishPageInfo
          }
        />
      </div>
    </div>
  );
}

// biome-ignore lint/complexity/noUselessLoneBlockStatements: <explanation>
{
  //  TODO:: Old code for the mobile language
  /* <aside className="flex lg:hidden py-2 sticky top-0 left-0 bg-white px-3 z-40">
        <div className="w-full rounded-lg border border-md border-cool-grey-200 gap-1 flex">
          <MenuDrawer
            trigger={
              <button
                type="button"
                className="flex flex-col w-1/2 px-3 py-2 items-center"
              >
                <div>
                  <h2 className="heading-9 text-cool-grey-400 uppercase">
                    By Category
                  </h2>
                  <h3
                    className={cn(
                      category?.name.toLowerCase(),
                      "heading-8 mt-0.5 lowercase"
                    )}
                  >
                    <span>{category?.name}</span>
                  </h3>
                </div>
              </button>
            }
          >
            <Menu
              currentOption={{
                name: category?.name ?? "",
                value: category?.id ?? "",
              }}
              title="Category"
              options={categoriesData.map((cat) => ({
                value: cat.id,
                name: cat.name.toLowerCase(),
              }))}
              onSelectOption={(option: IOption) => {
                const category = categoriesData.find(
                  (cat) => cat.id === option.value
                );
                if (category) onCategorySelect(category);
              }}
            />
          </MenuDrawer>

          <MenuDrawer
            trigger={
              <button
                type="button"
                className="relative flex items-center flex-col w-1/2 px-3 py-2 before:inline-block before:h-[calc(100%-16px)] before:bg-black-12a before:w-px before:absolute before:left-0 justify-center"
              >
                <div>
                  <h2 className="heading-9 text-cool-grey-400 uppercase">
                    By Language
                  </h2>
                  <h3 className="title-9 text-cool-grey-600 mt-0.5">
                    {laguageSelected?.displayName}
                  </h3>
                </div>
              </button>
            }
          >
            <Menu
              currentOption={{
                value: laguageSelected?.iso2Code ?? "",
                name: laguageSelected?.displayName ?? "",
              }}
              title="Language"
              options={languageOptions}
              onSelectOption={onLanguageSelect}
            />
          </MenuDrawer>
        </div>
      </aside> */
}
