import { useState, useEffect } from "react";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import {
  useGetQuestsQuery,
  QuestMembershipFilterValues,
  type GetQuestsQueryVariables,
  type B2bQuest,
} from "types/__generated__/graphql";

interface UseQuestOptions {
  variables: Omit<GetQuestsQueryVariables, "membership">;
  skippable?: boolean;
}

function useQuest({ variables, skippable = false }: UseQuestOptions) {
  const { userAccessState } = useUserState();
  const [quests, setQuests] = useState<B2bQuest[]>([]);
  const [pagination, setPagination] = useState<{
    after?: string;
    before?: string;
    first?: number;
    last?: number;
  }>({ first: 20 });

  // Reset quests and pagination when the category changes
  useEffect(() => {
    setQuests([]);
    setPagination({ first: 20 });
  }, [variables.category, variables.language]);

  const { data, loading, error, fetchMore } = useGetQuestsQuery({
    skip: !variables.category || skippable,
    variables: {
      ...variables,
      ...pagination, // Include pagination variables
      membership: QuestMembershipFilterValues.All,
      owned: userAccessState === UserAccessStateEnum.Premium,
    },
    onCompleted: (data) => {
      // Append new quests to the existing list
      if (data.b2bQuests?.edges) {
        const newQuests = data.b2bQuests.edges
          .map((edge) => edge.node)
          .filter((node): node is B2bQuest => Boolean(node));
        setQuests((prevQuests) => [...prevQuests, ...newQuests]);
      }
    },
  });

  const pageInfo = data?.b2bQuests?.pageInfo;

  const fetchNextPage = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          first: 20,
          before: undefined,
          last: undefined,
        },
      }).then((result) => {
        setPagination((prev) => ({
          ...prev,
          after: result.data.b2bQuests?.pageInfo.endCursor,
        }));
      });
    }
  };

  const fetchPreviousPage = () => {
    if (pageInfo?.hasPreviousPage) {
      fetchMore({
        variables: {
          before: pageInfo.startCursor,
          last: 20,
          after: undefined,
          first: undefined,
        },
      }).then((result) => {
        setPagination((prev) => ({
          ...prev,
          before: result.data.b2bQuests?.pageInfo.startCursor,
        }));
      });
    }
  };

  const fetchMoreQuests = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          first: 20,
        },
      }).then((result) => {
        setPagination((prev) => ({
          ...prev,
          after: result.data.b2bQuests?.pageInfo.endCursor,
        }));
      });
    }
  };

  return {
    data: quests,
    loading,
    error,
    pageInfo,
    fetchNextPage,
    fetchPreviousPage,
    fetchMoreQuests,
  };
}

export default useQuest;
