import type React from "react";
import { createContext, useRef, useContext } from "react";

interface MediaPlayerRefs {
  audioPlayerRef: React.RefObject<HTMLAudioElement>;
  mixerAudioPlayerRef: React.RefObject<HTMLAudioElement>;
  lastSelectedMixerIndexRef: React.MutableRefObject<number>;
  videoPlayerRef: React.RefObject<HTMLVideoElement>;
  seekBarRef: React.RefObject<HTMLDivElement>;
  seekBgRef: React.RefObject<HTMLDivElement>;
}

const MediaPlayerRefsContext = createContext<MediaPlayerRefs | null>(null);

export const MediaPlayerRefsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value: MediaPlayerRefs = {
    audioPlayerRef: useRef<HTMLAudioElement>(null),
    mixerAudioPlayerRef: useRef<HTMLAudioElement>(null),
    lastSelectedMixerIndexRef: useRef<number>(0),
    videoPlayerRef: useRef<HTMLVideoElement>(null),
    seekBarRef: useRef<HTMLDivElement>(null),
    seekBgRef: useRef<HTMLDivElement>(null),
  };

  return (
    <MediaPlayerRefsContext.Provider value={value}>
      {children}
    </MediaPlayerRefsContext.Provider>
  );
};

export const useMediaPlayerRefs = () => {
  const context = useContext(MediaPlayerRefsContext);
  if (!context) {
    throw new Error("useMediaPlayerRefs must be used within RefsProvider");
  }
  return context;
};
