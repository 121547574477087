import { MyLibraryListSkeleton } from "components/loading/MyLibraryListSkeleton";
import { CardVariant3 } from "components/quest/QuestCardVariant3";
import useLibrary from "hooks/useLibrary";
import { QuestStatus } from "types/interfaces";
import EmptyState from "../components/EmptyState";

const InProgress = () => {
  const { data: quests, loading } = useLibrary({
    first: 30,
    status: QuestStatus.InProgress,
  });

  if (loading) return <MyLibraryListSkeleton />;

  return (
    <div className="flex flex-col gap-8 ">
      <p className="heading-5 hidden lg:block">In progress</p>
      {quests && quests.length === 0 ? (
        <EmptyState section="in-progress" />
      ) : (
        <div className="items-stretch space-y-2">
          <p className="heading-7">{`Quests (${quests?.length})`}</p>
          <div className={"flex flex-col w-full md:flex gap-5"}>
            {quests?.map((quest) => (
              <CardVariant3 key={quest.id} quest={quest} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InProgress;
