import {
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "libs/classMerger";
import Icons from "components/icons/Icons";
import { Button } from "components/Button";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { Skeleton } from "components/Skeleton";
import type { Resource } from "types/__generated__/graphql";

// PORTED from @priyalathasubramanian work
const SlideProgress = ({
  total,
  currentIndex,
  onSlideClick,
  scrollRef,
}: {
  total: number;
  currentIndex: number;
  onSlideClick: (index: number) => void;
  scrollRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <div className="flex gap-6">
      <div className="flex gap-3 items-center">
        {Array.from({ length: total }).map((_, index) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            className={cn(
              "flex flex-row gap-3 items-center",
              currentIndex === index
                ? "animate-controls-slide-right"
                : "animate-controls-slide-left"
            )}
          >
            <button
              type="button"
              onClick={() => onSlideClick(index)}
              className={cn(
                "text-white/40 hover:text-white heading-9",
                currentIndex === index && "!text-white"
              )}
            >
              {index + 1 < 10 ? `0${index + 1}` : index + 1}
            </button>
            <div
              className={cn(
                "hidden w-20 h-0.5 bg-white/40 relative",
                currentIndex === index && "!flex"
              )}
            >
              <div
                className={cn(
                  "h-full bg-white transition-all",
                  currentIndex === index && "animate-progress-bar"
                )}
              />
            </div>
          </div>
        ))}
      </div>
      <Button
        type="button"
        variant="ghost"
        size="roundedIcon"
        onClick={() =>
          scrollRef.current?.scrollIntoView({
            behavior: "smooth",
          })
        }
        className="p-2 bg-brown-12a rounded-full h-14 w-14 gap-[10px] flex"
      >
        <Icons
          name="arrow-down-right-filled"
          className="h-8 w-8"
          fill="white"
        />
      </Button>
    </div>
  );
};

export const CarouselPlayerBanner = ({
  type,
  bannerTitle = "Title",
  data,
  isFetching,
  fetchingError,

  handleScrollNavigationRef,
}: {
  type: "meditations" | "soundscapes";
  bannerTitle?: string;
  data: Resource[] | null;
  isFetching?: boolean;
  fetchingError?: unknown;
  handleScrollNavigationRef: RefObject<HTMLDivElement>;
}) => {
  const soundscape = type === "soundscapes";
  const [currentItem, setCurrentItem] = useState<number>(0);
  // isFading controls the fade-out / fade-in opacity transition
  const [isFading, setIsFading] = useState(false);
  // Tracks the next index item to potentially display during a fade transition
  const [nextItem, setNextItem] = useState<number>(1);

  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const track = useMediaPlayerStore((state) => state.track);
  const setTrack = useMediaPlayerStore((state) => state.setTrack);
  const setTimerInfinity = useMediaPlayerStore(
    (state) => state.setTimerInfinity
  );
  const setSoundscapeMode = useMediaPlayerUIStore(
    (state) => state.setSoundscapeMode
  );
  const { play, pause, playTrack } = useMediaPlayerHook();

  const autoTransitionRef = useRef<NodeJS.Timeout | null>(null);
  const fadeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handlePlayPause = useCallback(
    async (trackData: Resource) => {
      setSoundscapeMode(soundscape);
      setTimerInfinity(soundscape);

      // Toggle play if the same track is selected
      const isCurrentTrack = track?.id === trackData.id;
      if (isCurrentTrack) {
        isPlaying ? pause() : play();
      } else {
        setTrack(trackData);
        playTrack({
          trackToPlay: trackData,
          soundscapeMode: soundscape,
        });
      }
    },
    [
      isPlaying,
      pause,
      play,
      playTrack,
      setSoundscapeMode,
      setTimerInfinity,
      setTrack,
      track,
    ]
  );

  const transitionSlide = useCallback(
    (index: number) => {
      // Clear any previous fade timers before starting a new transition
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
      if (autoTransitionRef.current) {
        clearInterval(autoTransitionRef.current);
      }

      setIsFading(true);
      fadeTimeoutRef.current = setTimeout(() => {
        setCurrentItem(index);
        setNextItem((index + 1) % (data?.length || 1));
        setIsFading(false);
        startAutoTransition();
      }, 300);
    },
    [data]
  );

  const handleSlideChange = useCallback(
    (index: number) => {
      transitionSlide(index);
    },
    [transitionSlide]
  );

  const startAutoTransition = useCallback(() => {
    if (!data || data.length <= 1) return;

    // Clear any existing interval to avoid multiple intervals running concurrently
    if (autoTransitionRef.current) {
      clearInterval(autoTransitionRef.current);
    }

    autoTransitionRef.current = setInterval(() => {
      setIsFading(true);
      fadeTimeoutRef.current = setTimeout(() => {
        setCurrentItem((prev) => {
          const next = (prev + 1) % data.length;
          setNextItem((next + 1) % data.length); // Preload next-next slide
          return next;
        });
        setIsFading(false);
      }, 300);
    }, 3000);
  }, [data]);

  // Start auto-transitioning when the list is loaded
  useEffect(() => {
    if (data && data.length > 0) {
      startAutoTransition();
    }

    return () => {
      // Cleanup timers on unmount
      if (autoTransitionRef.current) {
        clearInterval(autoTransitionRef.current);
      }
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
    };
  }, [data, startAutoTransition]);

  if (isFetching || fetchingError || !data) {
    return (
      <Skeleton className="w-xl h-[398px] md:h-[392px] lg:h-[690px] relative" />
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="w-xl h-[398px] md:h-[392px] lg:h-[690px] relative">
        <div className="w-full">
          <div className="relative">
            {data.map((item, index: number) => (
              <div
                key={item.id}
                className="absolute inset-0 justify-center pl-0"
              >
                <div className="relative w-full">
                  <img
                    src={item.landscapeCoverAsset?.url}
                    className={cn(
                      "object-cover object-top w-full h-[398px] md:h-[392px] lg:h-[690px] transition-opacity duration-500 rounded-t-2xl lg:rounded-b-2xl",
                      // Show current slide, handle fading, and preloading of the next slide
                      currentItem === index
                        ? isFading
                          ? "opacity-0"
                          : "opacity-100"
                        : nextItem === index && isFading
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                    alt={`Slide ${index + 1}`}
                  />
                  <div className="lg:hidden absolute inset-x-0 bottom-0 h-[25%] bg-gradient-to-t from-black to-transparent" />
                  <div className="flex flex-col absolute inset-0 justify-end p-4 md:p-6 lg:p-10 w-full gap-8">
                    <div
                      className={cn(
                        "hidden lg:flex display-2 text-white w-full",
                        currentItem !== index ? "mb-[112px]" : ""
                      )}
                    >
                      {bannerTitle}
                    </div>
                    <div
                      className={cn(
                        "flex h-20 items-center justify-between z-10",
                        currentItem === index
                          ? "slide-in-animation-right-left-slow"
                          : currentItem < index
                          ? "hidden"
                          : "slide-out-animation-left"
                      )}
                    >
                      <div className="flex gap-2 items-center overflow-hidden w-[561px]">
                        <Button
                          variant="ghost"
                          size="roundedIcon"
                          className="w-fit h-full"
                          onClick={() => handlePlayPause(item)}
                        >
                          {track?.id === item.id ? (
                            !isPlaying ? (
                              <Icons
                                name="play-circle-filled"
                                height={78}
                                width={78}
                                fill="#e5e7eb"
                              />
                            ) : (
                              <Icons
                                name="pause-circle-filled"
                                height={78}
                                width={78}
                                fill="#e5e7eb"
                              />
                            )
                          ) : (
                            <Icons
                              name="play-circle-filled"
                              height={78}
                              width={78}
                              fill="#e5e7eb"
                            />
                          )}
                        </Button>
                        <div className="flex flex-col gap-[2px] text-white justify-center nowrap">
                          <div className="heading-4 line-clamp-1">
                            {item.title}
                          </div>
                          <div className="caption-disclaimer">
                            by {item.author?.name ?? "Author"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Desktop slide progress control */}
        <div className="hidden lg:flex absolute top-[85%] right-1 transform -translate-x-1/2 z-30">
          <SlideProgress
            total={data.length}
            currentIndex={currentItem}
            onSlideClick={handleSlideChange}
            scrollRef={handleScrollNavigationRef}
          />
        </div>
      </div>
      {/* Mobile view */}
      <div className="lg:hidden flex flex-col gap-4 px-4 md:px-6 pb-4 md:pb-6 bg-black w-full rounded-b-2xl">
        <div className="flex heading-1 text-white w-full">
          Soundscapes of the week
        </div>
        <div className="flex justify-end">
          <SlideProgress
            total={data.length}
            currentIndex={currentItem}
            onSlideClick={handleSlideChange}
            scrollRef={handleScrollNavigationRef}
          />
        </div>
      </div>
    </div>
  );
};
