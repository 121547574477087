import { useState } from "react";
import BannerSkeleton from "components/loading/BannerSkeleton";
import FeaturedListComponent from "../../../components/FeaturedListComponent";
import type { NavigationEnum } from "router/navigationType";
import type { Resource } from "types/__generated__/graphql";
import LockedModal from "components/LockedModal";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useNavigate } from "react-router-dom";

const FeaturedSection = ({
  data,
  isFetching,
  error,
  copy,
  type,
  handleDiscoverMoreNavigation,
}: {
  data: Resource[] | null;
  isFetching: boolean;
  error?: Error;
  copy: {
    title: string;
    description: string;
  };
  type: "meditations" | "soundscapes";
  handleDiscoverMoreNavigation: NavigationEnum;
}) => {
  if (!data || isFetching || error)
    return <BannerSkeleton circleThumbnail={true} />;

  const [banner, setBanner] = useState(data[0].landscapeCoverAsset?.url);
  const [selectedTrackBanner, setSelectedTrackBanner] = useState(banner);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userAccessState } = useUserState();
  const navigate = useNavigate();

  const handleDiscoverAction = () => {
    if (userAccessState !== UserAccessStateEnum.Premium) {
      setIsModalOpen(true);
    }
    else {
      navigate(handleDiscoverMoreNavigation);
    }
  }
  return (
    <div>
      <section className="container px-5" style={{ maxWidth: "1800px" }}>
        {data && (
          <FeaturedListComponent
            title={copy.title}
            description={copy.description}
            backdropImagePath={banner || ""}
            buttonLink={handleDiscoverMoreNavigation}
            data={data}
            onHover={(image: string) => {
              setBanner(image);
            }}
            type={type}
            onLeave={() => setBanner(selectedTrackBanner)}
            onClick={(image: string) => setSelectedTrackBanner(image)}
            handleMoreAction={handleDiscoverAction}
          />
        )}
      </section>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </div>
  );
};

export default FeaturedSection;
