import { cn } from "libs/classMerger";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import { Tooltip } from "../ui/tooltip";
import { Button } from "../Button";
import Icons from "../icons/Icons";
import { useEffect, useState } from "react";

export const MediaPlayerTrackInfo = () => {
  const track = useMediaPlayerStore((state) => state.track);
  const fullScreenMode = useMediaPlayerUIStore((state) => state.fullScreenMode);
  const soundscapeMode = useMediaPlayerUIStore((state) => state.soundscapeMode);
  const { toggleFullScreen } = useMediaPlayerHook();

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      setAnimated(true);
    }, 1000 * 2);
  });

  if (!track) return null;

  return (
    <div
      className={cn(
        "flex items-center z-10",
        fullScreenMode &&
          "text-white t-6 px-4 lg:px-10 w-full grid gap-6 transition-transform duration-700"
        // fullScreenMode &&
        //   "absolute z-50 bottom-[150px] lg:bottom-[116px] text-white t-6 px-4 lg:px-10 w-full grid gap-6 transition-transform duration-700",
        // animated
        //   ? "scale-100 opacity-100"
        //   : "scale-150 translate-x-[400px] translate-y-[-50px] opacity-50"
      )}
    >
      <div className="flex items-center gap-4">
        <div
          className={cn(
            "size-[64px] lg:size-[56px] object-cover",
            soundscapeMode ? "rounded-full" : "rounded-lg"
          )}
        >
          <img
            src={track.coverAsset?.url}
            className={cn(soundscapeMode ? "rounded-full" : "rounded-lg")}
            alt={track.title}
          />
        </div>

        <div>
          <div className="title-9 text-white w-full">{track.title}</div>
          <div className="caption-disclaimer text-[#b3b8c1]">
            {track.author?.name ?? "Author"}
          </div>
        </div>
        {!fullScreenMode && (
          <div className="flex lg:hidden">
            <Tooltip
              delayDuration={0}
              tooltipContent={
                <p className="caption-disclaimer">Enter full screen</p>
              }
            >
              <Button
                variant="icon"
                size="icon"
                className="text-white/70 hover:text-white"
                onClick={() => toggleFullScreen()}
              >
                <Icons name="chevron-up-outlined" width={20} height={20} />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
