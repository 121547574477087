import * as React from "react";
import { useMediaPlayerRefs } from "context/MediaPlayerRefsContext";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaMixerStore from "stores/mediaPlayerMixerStore";
import { getRendition } from "utils/mediaHelpers";

interface AudioPlayerProps extends React.AudioHTMLAttributes<HTMLAudioElement> {
  src: string | undefined;
}

const AudioPlayer = React.forwardRef<HTMLAudioElement, AudioPlayerProps>(
  ({ src, ...props }, ref) => (
    <audio ref={ref} src={src || undefined} preload="metadata" {...props}>
      <track kind="captions" />
    </audio>
  )
);
AudioPlayer.displayName = "AudioPlayer";

const MediaPlayerV2AudioController = () => {
  const { audioPlayerRef, mixerAudioPlayerRef } = useMediaPlayerRefs();
  const track = useMediaPlayerStore((state) => state.track);
  const mixer = useMediaMixerStore((state) => state.mixerTrack);

  const trackRendition = getRendition(
    track?.mediaAsset?.renditions || [],
    ["audio/mpeg", "audio/aac", "audio/ogg"]
    // "No preferred audio rendition found for main track."
  );

  const mixerRendition = getRendition(
    mixer?.mediaAsset?.renditions || [],
    ["audio/mpeg", "audio/aac", "audio/ogg"]
    // "No preferred audio rendition found for mixer track."
  );

  const trackSrc = trackRendition?.src || track?.mediaAsset?.url || null;
  const mixerSrc = mixerRendition?.src || mixer?.mediaAsset?.url || null;

  return (
    <>
      <AudioPlayer ref={audioPlayerRef} src={trackSrc || undefined} />
      <AudioPlayer ref={mixerAudioPlayerRef} src={mixerSrc || undefined} />
    </>
  );
};

export default MediaPlayerV2AudioController;
