import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_PICKED_FOR_YOU_QUESTS_PUBLIC } from "services/graphql/queries/dsApiQueries";
import {
  useGetPickedForYouQuestsQuery,
  type GetPickedForYouQuestsQueryVariables,
  type B2bQuest,
} from "types/__generated__/graphql";

function useGetPickedForYouQuests(
  variables: Omit<GetPickedForYouQuestsQueryVariables, "orgId">
) {
  const { isAuthenticated, organizationData } = useUserState();

  const {
    data: publicData,
    loading: publicDataLoading,
    error: publicDataError,
  } = useQuery(GET_PICKED_FOR_YOU_QUESTS_PUBLIC, {
    skip: isAuthenticated,
    variables: { first: 4 },
  });

  const {
    data,
    loading: orgDataLoading,
    error: orgDataError,
  } = useGetPickedForYouQuestsQuery({
    skip: !isAuthenticated,
    variables: {
      ...variables,
      orgId: organizationData?.id?.toString() || "",
    },
  });

  const quests = data?.recommendedB2bQuestsPickedForYou?.edges?.map(
    (edge) => edge?.node?.quest as B2bQuest
  );
  const publicQuests = publicData?.pickedForYouRecommendations as B2bQuest[];

  return {
    data: quests || publicQuests,
    loading: publicDataLoading || orgDataLoading,
    error: publicDataError || orgDataError,
  };
}

export default useGetPickedForYouQuests;
