import { getAuth0Client } from "config/authConfig";
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  useGetCurrentOrganizationQuery,
  type GetCurrentOrganizationQuery,
} from "types/__generated__/graphql";

export enum UserAccessStateEnum {
  Loading = "loading",
  Unauthenticated = "unauthenticated",
  Freemium = "freemium",
  Premium = "premium",
}

interface UserStateContextProps {
  userAccessState: UserAccessStateEnum;
  organizationData: GetCurrentOrganizationQuery["currentOrganization"] | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: Error | null;
}

type Action =
  | { type: "SET_LOADING" }
  | {
      type: "SET_AUTHENTICATED";
      payload: GetCurrentOrganizationQuery["currentOrganization"] | null;
    }
  | { type: "SET_UNAUTHENTICATED" }
  | { type: "SET_ERROR"; payload: Error };

function userStateReducer(
  state: UserStateContextProps,
  action: Action
): UserStateContextProps {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Loading,
        loading: true,
        isAuthenticated: false,
      };
    case "SET_AUTHENTICATED":
      return {
        ...state,
        userAccessState:
          action.payload?.id && action.payload.id !== 469
            ? UserAccessStateEnum.Premium
            : UserAccessStateEnum.Freemium,
        organizationData: action.payload,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        organizationData: null,
        isAuthenticated: false,
        loading: false,
        error: null,
      };
    case "SET_ERROR":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        isAuthenticated: false,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

const initialState: UserStateContextProps = {
  userAccessState: UserAccessStateEnum.Loading,
  organizationData: null,
  isAuthenticated: false,
  loading: true,
  error: null,
};

export const UserStateContext = createContext<
  UserStateContextProps | undefined
>(undefined);

interface UserStateProviderProps {
  children: ReactNode;
}

async function checkAuthentication(dispatch: React.Dispatch<Action>) {
  dispatch({ type: "SET_LOADING" });
  try {
    const auth0 = await getAuth0Client();
    const authenticated = await auth0.isAuthenticated();
    if (authenticated) {
      dispatch({ type: "SET_AUTHENTICATED", payload: null });
    } else {
      dispatch({ type: "SET_UNAUTHENTICATED" });
    }
  } catch (error) {
    dispatch({ type: "SET_ERROR", payload: error as Error });
  }
}

function useOrganizationData(
  isAuthenticated: boolean,
  dispatch: React.Dispatch<Action>
) {
  const {
    loading,
    data: orgData,
    error,
  } = useGetCurrentOrganizationQuery({ skip: !isAuthenticated });

  useEffect(() => {
    if (!isAuthenticated || loading) return;
    if (error) {
      dispatch({ type: "SET_ERROR", payload: error });
    } else if (orgData?.currentOrganization) {
      dispatch({
        type: "SET_AUTHENTICATED",
        payload: orgData.currentOrganization,
      });
    } else {
      dispatch({ type: "SET_AUTHENTICATED", payload: null });
    }
  }, [isAuthenticated, loading, orgData, error, dispatch]);
}

export const UserStateProvider = ({ children }: UserStateProviderProps) => {
  const [state, dispatch] = useReducer(userStateReducer, initialState);

  useEffect(() => {
    checkAuthentication(dispatch);
  }, []);

  useOrganizationData(state.isAuthenticated, dispatch);

  return (
    <UserStateContext.Provider value={state}>
      {children}
    </UserStateContext.Provider>
  );
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserStateProvider");
  }
  return context;
};
