// hooks/useMediaPlayerTimer.ts
import { useRef, useEffect, useCallback } from "react";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import { useMediaPlayerRefs } from "context/MediaPlayerRefsContext";

import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";

const useMediaPlayerTimer = () => {
  const { audioPlayerRef } = useMediaPlayerRefs();
  const setIsPlaying = useMediaPlayerStore((state) => state.setIsPlaying);
  const { resetTimer } = useMediaPlayerStore();

  const { soundscapeMode } = useMediaPlayerUIStore();

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const handleLoopRef = useRef<(() => void) | null>(null);

  // Ref to keep the latest isPlaying value
  const isPlayingRef = useRef(false);

  // useEffect(() => {
  //   if (!soundscapeMode) return;
  //   isPlayingRef.current = useMediaPlayerStore.getState().isPlaying;
  // }, [useMediaPlayerStore.getState().isPlaying, soundscapeMode]);

  const handleSetTimer = useCallback(
    (duration: number) => {
      if (!soundscapeMode || duration <= 0) return;

      const audio = audioPlayerRef?.current;
      if (!audio) {
        console.warn("Audio player reference is null.");
        return;
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      if (handleLoopRef.current) {
        audio.removeEventListener("ended", handleLoopRef.current);
        handleLoopRef.current = null;
      }

      audio.pause();
      audio.currentTime = 0;
      audio.play();
      setIsPlaying(true);

      intervalRef.current = setInterval(() => {
        const currentTimeInSeconds = audio.currentTime;
        console.log("currentTimeInSeconds", currentTimeInSeconds);

        if (currentTimeInSeconds >= duration) {
          console.log("Timer reached duration. Pausing audio.");
          audio.pause();
          audio.currentTime = 0;
          setIsPlaying(false);
          resetTimer();
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            console.log("Cleared interval after timer completion.");
          }
          console.log("Timer ended, audio paused.");
        }
      }, 1000);
    },
    [audioPlayerRef, setIsPlaying, soundscapeMode]
  );

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        console.log("Cleanup: Cleared interval on unmount.");
      }
      if (handleLoopRef.current) {
        audioPlayerRef?.current?.removeEventListener(
          "ended",
          handleLoopRef.current
        );
        console.log("Cleanup: Removed 'ended' event listener on unmount.");
      }
    };
  }, [audioPlayerRef]);

  return { handleSetTimer };
};

export default useMediaPlayerTimer;
