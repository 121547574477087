import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useChannelBySlug from "hooks/useChannelBySlug";
import { DetailCategoriesHeroBanner } from "components/soundscapesAndMeditations/DetailCategoriesHeroBanner";
import { CollectionsListGridSection } from "components/soundscapesAndMeditations/CollectionsListGridSection";
import { CategoryListGridSection } from "components/soundscapesAndMeditations/CategoryListGridSection";
import {
  CollectionHighlightStatusFilterEnum,
  type B2bCollection,
} from "types/__generated__/graphql";

export default function SoundscapesCategoriesView() {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const exploreCategoriesRef = useRef<HTMLDivElement | null>(null);
  const [currentCategory, setCurrentCategory] = useState<B2bCollection | null>(
    null
  );

  const { data: collectionsData, loading: fetchingData } = useChannelBySlug({
    slug: "b2b-soundscape-categories",
    highlightStatus: CollectionHighlightStatusFilterEnum.NotFeatured,
  });

  console.log("collectionsData", collectionsData);

  const filteredCategoriesData = useMemo(() => {
    if (!collectionsData?.collections) return null;
    return (
      collectionsData.collections.find(
        (collection) => collection.slug === slug
      ) ?? null
    );
  }, [collectionsData, slug]);

  const heroTitle = slug
    ? currentCategory?.title ?? filteredCategoriesData?.title ?? "Category"
    : collectionsData?.title ?? "Soundscape Categories";

  const heroDescription = slug
    ? currentCategory?.description ??
      filteredCategoriesData?.description ??
      "Explore the selected category for more soundscapes."
    : collectionsData?.description ??
      "Discover the transformative power of sound for working professionals. Our library of soundscapes offers the perfect escape from the demands of work. Whether you need to relax, focus, or boost your productivity, we have the soundscape for you.";

  const heroImageUrl = slug
    ? currentCategory?.landscapeCoverAsset?.url ??
      filteredCategoriesData?.landscapeCoverAsset?.url ??
      ""
    : "";

  function handleChangeCategory(category: B2bCollection) {
    setCurrentCategory(category);
  }

  function handleScrollToCategories() {
    exploreCategoriesRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="flex flex-col gap-16 container mx-auto">
      <DetailCategoriesHeroBanner
        copy={{
          title: heroTitle,
          description: heroDescription,
          outerNavigationCTA: "Explore collections",
        }}
        bannerVideoUrl={collectionsData?.trailerLoopingAsset?.url}
        bannerImageUrl={heroImageUrl}
        isFetching={fetchingData || !collectionsData}
        handleInnerNavigation={handleScrollToCategories}
        handleOuterNavigation={() => navigate("/soundscapes/collections")}
      />

      {slug ? (
        <CategoryListGridSection
          type="soundscapes"
          copy={{
            title: `Soundscapes from ${
              currentCategory?.title ??
              filteredCategoriesData?.title ??
              "Category"
            }`,
          }}
          sectionRef={exploreCategoriesRef}
          isFetching={fetchingData || !filteredCategoriesData}
          collectionsData={collectionsData}
          filteredCategoriesData={currentCategory ?? filteredCategoriesData}
          handleCategoryChange={handleChangeCategory}
        />
      ) : (
        <CollectionsListGridSection
          type="soundscapes"
          copy={{
            title: "All categories",
          }}
          sectionRef={exploreCategoriesRef}
          isFetching={fetchingData || !collectionsData}
          collectionsData={collectionsData?.collections ?? []}
          handleCategoryNavigation={(slug) =>
            navigate(`/soundscapes/categories/${slug}`)
          }
        />
      )}
    </div>
  );
}
