import Icons from "../../icons/Icons";
import { Button } from "../../Button";
import { Tooltip } from "../../ui/tooltip";
import { MediaPlayerVolumeControl } from "../MediaPlayerVolumeControl";
import { MediaPlayerTimerModal } from "../MediaPlayerTimerModal";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import { cn } from "libs/classMerger";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import { MediaPlayerMainControls } from "../controls/MediaPlayerMainControls";
import { MediaPlayerMixer } from "../MediaPlayerMixer";
import { useShallow } from "zustand/shallow";

interface ControlProps {
  isPlaying?: boolean;
  onPlayPause?: () => void;
  immersionMode?: boolean;
  soundscapeMode: boolean;
  fullScreenMode: boolean;
  toggleImmersion: () => void;
  toggleFullScreen: () => void;
  close: () => void;
}

const FullScreenModeControls = ({
  immersionMode,
  soundscapeMode,
  fullScreenMode,
  toggleImmersion,
  toggleFullScreen,
  close,
}: ControlProps) => {
  return (
    <>
      <div
        className={cn(
          "flex lg:hidden items-center gap-x-3.5 place-self-end",
          "media-controller-fullscreen-hover-transition"
        )}
      >
        <div className="absolute translate-x-1/2 right-1/2">
          <MediaPlayerMainControls />
        </div>

        <Tooltip
          delayDuration={0}
          tooltipContent={
            <p className="caption-disclaimer">
              {immersionMode ? "Disable immersive" : "Enable immersive"}
            </p>
          }
        >
          <Button
            variant="icon"
            size="icon"
            className={cn(
              "text-white/70 hover:text-white",
              immersionMode && "text-[#e85546]"
            )}
            onClick={() => toggleImmersion()}
          >
            <Icons name="meditation-outlined" className="size-[24px]" />
          </Button>
        </Tooltip>
      </div>
      {/* <div className="lg:hidden">
        {soundscapeMode ? (
          <MediaPlayerTimerModal fullScreenMode={true} />
        ) : (
          <MediaPlayerMixer />
        )}
      </div> */}

      <div className="hidden lg:flex items-center gap-x-3.5 place-self-end">
        <div className="absolute translate-x-1/2 right-1/2">
          <MediaPlayerMainControls />
        </div>

        <Tooltip
          delayDuration={0}
          tooltipContent={
            <p className="caption-disclaimer">
              {immersionMode ? "Disable immersive" : "Enable immersive"}
            </p>
          }
        >
          <Button
            variant="icon"
            size="icon"
            className={cn(
              "text-white/70 hover:text-white",
              immersionMode && "text-[#e85546]"
            )}
            onClick={() => toggleImmersion()}
          >
            <Icons name="meditation-outlined" className="size-[24px]" />
          </Button>
        </Tooltip>

        {/* {soundscapeMode ? (
          <MediaPlayerTimerModal fullScreenMode={false} />
        ) : (
          <MediaPlayerMixer overrideFullscreenMode={false} />
        )} */}

        <MediaPlayerVolumeControl className="w-[100px]" />

        <Tooltip
          delayDuration={0}
          tooltipContent={
            <p className="caption-disclaimer">
              {fullScreenMode ? "Exit Fullscreen" : "Enter fullscreen"}
            </p>
          }
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => toggleFullScreen()}
          >
            <Icons name="maximize-2-outlined" className="size-[20px]" />
          </Button>
        </Tooltip>

        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">Close</p>}
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => close()}
          >
            <Icons name="x-circle-outlined" className="size-[20px]" />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const NormalModeControls = ({
  isPlaying,
  onPlayPause,
  soundscapeMode,
  fullScreenMode,
  toggleFullScreen,
  close,
}: ControlProps) => {
  return (
    <>
      <div className="hidden lg:flex items-center gap-x-3.5">
        <div className="absolute translate-x-1/2 right-1/2">
          <MediaPlayerMainControls />
        </div>
        {/* {soundscapeMode ? (
          <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Timer</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className={cn(
                "text-white/70 hover:text-white",
                fullScreenMode && "hidden"
              )}
            >
              <MediaPlayerTimerModal fullScreenMode={false} />
            </Button>
          </Tooltip>
        ) : (
          <MediaPlayerMixer />
        )} */}

        <MediaPlayerVolumeControl className="w-[100px]" />
        <Tooltip
          delayDuration={0}
          tooltipContent={
            <p className="caption-disclaimer">Enter full screen</p>
          }
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => toggleFullScreen()}
          >
            <Icons name="maximize-2-outlined" className="size-[20px]" />
          </Button>
        </Tooltip>

        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">Close</p>}
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => close()}
          >
            <Icons name="x-circle-outlined" className="size-[20px]" />
          </Button>
        </Tooltip>
      </div>

      <div className="flex lg:hidden items-center gap-x-3.5">
        {/* {soundscapeMode ? (
          <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Timer</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className={cn("text-white/70 hover:text-white")}
            >
              <MediaPlayerTimerModal fullScreenMode={false} />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip
            delayDuration={0}
            tooltipContent={<p className="caption-disclaimer">Mixer</p>}
          >
            <Button
              variant="icon"
              size="icon"
              className="text-white/70 hover:text-white"
            >
              <Icons name="sliders-outlined" width={20} height={20} />
            </Button>
          </Tooltip>
        )} */}

        <Tooltip
          delayDuration={0}
          tooltipContent={
            <p className="caption-disclaimer">{isPlaying ? "Pause" : "Play"}</p>
          }
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={onPlayPause}
          >
            {isPlaying ? (
              <Icons name="pause-circle-filled" width={40} height={40} />
            ) : (
              <Icons name="play-circle-filled" width={40} height={40} />
            )}
          </Button>
        </Tooltip>

        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">Close</p>}
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => close()}
          >
            <Icons name="x-circle-outlined" className="size-[20px]" />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export const MediaPlayerUtils = () => {
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const { fullScreenMode, immersionMode, soundscapeMode } =
    useMediaPlayerUIStore(
      useShallow((state) => ({
        isTimerSet: state.isTimerSet,
        fullScreenMode: state.fullScreenMode,
        show: state.show,
        soundscapeMode: state.soundscapeMode,
        immersionMode: state.immersionMode,
      }))
    );
  const { onPlayPause, toggleFullScreen, close, toggleImmersion } =
    useMediaPlayerHook();

  return (
    <>
      {fullScreenMode ? (
        <FullScreenModeControls
          immersionMode={immersionMode}
          soundscapeMode={soundscapeMode}
          fullScreenMode={fullScreenMode}
          toggleImmersion={toggleImmersion}
          toggleFullScreen={toggleFullScreen}
          close={close}
        />
      ) : (
        <NormalModeControls
          isPlaying={isPlaying}
          onPlayPause={onPlayPause}
          soundscapeMode={soundscapeMode}
          fullScreenMode={fullScreenMode}
          toggleImmersion={toggleImmersion}
          toggleFullScreen={toggleFullScreen}
          close={close}
        />
      )}
    </>
  );
};
