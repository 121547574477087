// @ts-expect-error No type definitions for color-thief
import ColorThief from "colorthief";

function rgbArrayToHex([r, g, b]: number[]): string {
  const toHex = (val: number) => val.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export async function extractColorsFromImageData(
  imageUrl: string,
  maxColors = 5
): Promise<string[]> {
  const colorThief = new ColorThief();
  const img = new Image();
  img.crossOrigin = "Anonymous";

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
    img.src = imageUrl;
  });

  const palette = colorThief.getPalette(img, maxColors);

  return palette.map(rgbArrayToHex);
}
