import { useUserState } from "context/userContext";
import {
  useRecommendedB2bSoundscapesOfTheWeekQuery,
  type RecommendedB2bSoundscapesOfTheWeekQueryVariables,
  type Resource,
} from "types/__generated__/graphql";

function useSoundscapeOfTheWeek() {
  const { organizationData } = useUserState();
  const variables: RecommendedB2bSoundscapesOfTheWeekQueryVariables = {
    first: 4,
    orgId: organizationData?.id?.toString() ?? "0",
  };

  const { data, loading, error } = useRecommendedB2bSoundscapesOfTheWeekQuery({
    variables,
    fetchPolicy: "no-cache", // TODO:: There's caching issue with this query
  });

  const soundscapes = data?.recommendedB2bSoundscapesOfTheWeek?.edges?.map(
    (edge) => edge.node?.soundscape
  ) as Resource[];

  return { data: soundscapes, loading, error };
}

export default useSoundscapeOfTheWeek;
