import { useLazyQuery } from "@apollo/client";
import { GET_RESOURCE_BY_ID } from "services/graphql/queries/index";

function useGetResourceById() {
  const [fetchResource, { data, loading, error }] =
    useLazyQuery(GET_RESOURCE_BY_ID);

  return { fetchResource, data, loading, error };
}

export default useGetResourceById;
