import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { NavigationEnum } from "router/navigationType";
import { type CardProps, CarouselCard } from "./CarouselCard";
import useScreenType from "hooks/useScreenType";

const cards: CardProps[] = [
  {
    id: 0,
    entity: NavigationEnum.Programs,
    label: "Quests",
    text: "Learnings that are short, daily lessons designed to keep you engaged and growing at work",
    link: "/hero/banner.mp4",
  },
  {
    id: 1,
    entity: NavigationEnum.Meditations,
    label: "Meditations",
    text: "Guided mindfulness practices to enhance focus, reduce stress, and boost general wellbeing",
    link: "/hero/meditation.mp4",
  },
  {
    id: 2,
    entity: NavigationEnum.Soundscapes,
    subEntity: "Soundscapes",
    label: "Soundscapes",
    text: "Relaxing audio experiences to help you unwind, focus, and multitask",
    link: "/hero/banner-med-series.mp4",
  },
];

export function HeroCarousel({ onMoreDetail }: { onMoreDetail?: (ref: number) => void; }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [centerSlideIndex, setCenterSlideIndex] = useState<number | null>(null);
  const screenType = useScreenType();

  const updateCenterSlide = useCallback(() => {
    if (emblaApi) {
      const selected = emblaApi.selectedScrollSnap();
      setCenterSlideIndex(selected);
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", updateCenterSlide);
      updateCenterSlide();
    }

    return () => {
      if (emblaApi) {
        emblaApi.off("select", updateCenterSlide);
      }
    };
  }, [emblaApi, updateCenterSlide]);

  const handleSlideClick = useCallback(
    (index: number) => {
      if (emblaApi) {
        emblaApi.scrollTo(index);
      }
    },
    [emblaApi]
  );

  return (
    <section className="mx-auto container">
      <div ref={emblaRef}>
        <div className="flex">
          {cards.map((card, index) => (
            <div
              className="shrink-0 h-full md:basis-3/4 mx-2"
              style={{ transform: `scale(${screenType.isMobile ? "0.8" : "1"})` }}
              key={card.label}
              aria-roledescription="slide"
            >
              <CarouselCard
                {...card}
                activeCarousel={centerSlideIndex}
                onClickSlide={() => handleSlideClick(index)}
                onMoreDetail={onMoreDetail}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
