import { WorkwayLogoSmall } from "../logo/WorkwayLogo";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";

interface MediaPlayerV2HeaderProps {
  title: string;
  subtitle: string;
}

export const MediaPlayerHeader = ({
  title,
  subtitle,
}: MediaPlayerV2HeaderProps) => {
  const { toggleFullScreen } = useMediaPlayerHook();
  return (
    <div className="absolute px-4 pt-6 pb-4 md:p-4 lg:px-14 lg:py-3 z-50 flex items-center justify-between lg:justify-start gap-2 h-[72px] md:h-[64px] lg:h-[89px] w-full bg-gradient-to-b from-black/40">
      <Button
        variant="icon"
        size="icon"
        className="text-white/70 hover:text-white inline-flex lg:hidden"
        onClick={() => toggleFullScreen()}
      >
        <Icons name="chevron-down-outlined" width={20} height={20} />
      </Button>
      <div className="hidden lg:inline-flex">
        <WorkwayLogoSmall />
      </div>
      <div className="text-white text-center lg:text-left">
        <div className="body-2-xs uppercase">{title}</div>
        <div className="heading-9 font-medium hover:underline">{subtitle}</div>
      </div>
      <Button
        variant="icon"
        size="icon"
        className="text-white/70 hover:text-white inline-flex lg:hidden"
      >
        <Icons name="more-vertical-outlined" width={16} height={16} />
      </Button>
    </div>
  );
};
