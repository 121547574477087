// Im not a graphql expert not sure this is standard but hey its works. I only got couple of hours to make this work

import { MutationsOperations } from "./mutations/mutationsOperations";

export enum AuthRequirement {
  REQUIRED = "REQUIRED",
  OPTIONAL = "OPTIONAL",
  NONE = "NONE",
}

export type GraphQLOperation = {
  operationName: string;
  authRequirement: AuthRequirement;
};

const authOptionalOperations: GraphQLOperation[] = [
  // QUERIES
  {
    operationName: "getPickedForYouQuests",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getQuestById",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getTrendingGloballyQuests",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getQuestBySlugAndLanguageB2b",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getPickedForYouQuestsPublic",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getSoundscapesOfTheWeek",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getMeditationOfTheDay",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getQuestsByCategory",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getLanguages",
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: "getResourcesByType",
    authRequirement: AuthRequirement.OPTIONAL,
  },

  //  MUTATIONS
  {
    operationName: MutationsOperations.ENROLL_USER,
    authRequirement: AuthRequirement.OPTIONAL,
  },
];
const getOperation = (
  operationName: string | null
): GraphQLOperation | undefined =>
  operationName
    ? authOptionalOperations.find((op) => op.operationName === operationName)
    : undefined;

/**
 * Retrieves the auth requirement status for an operation.
 * @returns The auth requirement: REQUIRED, OPTIONAL, or NONE.
 */
export const getAuthRequirement = (
  operationName: string | null
): AuthRequirement => {
  return getOperation(operationName)?.authRequirement ?? AuthRequirement.NONE;
};
