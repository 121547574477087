import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { app } from "@microsoft/teams-js";

const MessageHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        app.initialize().then(() => {
            app.getContext().then((context: app.Context) => {
                const subPageId = context.page.subPageId;
                if (subPageId) {
                    // Navigate based on subPageId
                    switch (subPageId) {
                        case 'openProgramsTab':
                            navigate('/programs');
                            break;
                        case 'open3MIQ':
                            navigate('/quests/en/questions');
                            break;
                        case 'open6PM':
                            navigate('/quests/en/learn-meditation');
                            break;
                        case 'openMeditaions':
                            navigate('/meditations');
                            break;
                        case 'openSoundscapes':
                            navigate('/soundscapes');
                            break;
                        default:
                            console.warn("Unknown subPageId:", subPageId);
                            break;
                    }
                    context.page.subPageId = '';
                }
            });
        });
        return () => {
            app.getContext().then((context: app.Context) => {
                context.page.subPageId = '';
            });
        };
    }, []);
    return null;
};

export default MessageHandler;