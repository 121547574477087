import {
  useEnrollUserMutation,
  useMarkPageAsCompletedMutation,
  useMarkTaskAsCompletedMutation,
  useGetQuestBySlugAndLanguageQuery,
  useToggleTaskCompletionMutation,
  type Task,
} from "types/__generated__/graphql";
import { useToast } from "hooks/use-toast";
import { useCallback } from "react";

const useQuestMutations = (slug?: string, lang?: string) => {
  const { toast } = useToast();

  // Fetch quest data
  const {
    data: questRaw,
    loading: questLoading,
    error: questDataError,
    refetch: refetchQuest,
  } = useGetQuestBySlugAndLanguageQuery({
    variables: {
      slugAndLanguage: {
        slug: slug || "",
        language: lang || "",
      },
    },
    skip: !slug || !lang,
    fetchPolicy: "cache-and-network",
  });

  const [enrollUser, { loading: enrollLoading, error: enrollError }] =
    useEnrollUserMutation();

  const [
    markPageAsCompleted,
    { loading: markPageLoading, error: markPageError },
  ] = useMarkPageAsCompletedMutation();

  const [
    markTaskAsCompleted,
    { loading: markTaskLoading, error: markTaskError },
  ] = useMarkTaskAsCompletedMutation();

  const [
    toggleTaskCompletion,
    { loading: toggleTaskCompletionLoading, error: toggleTaskCompletionError },
  ] = useToggleTaskCompletionMutation();

  const handleEnrollUser = async (releaseId: string) => {
    try {
      await enrollUser({ variables: { input: { releaseId } } });
      // Re-query the quest data after successful enrollment
      await refetchQuest();
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Failed to enroll user. Please try again!",
      });
    }
  };

  const handleMarkPageAsCompleted = async (pageId: string) => {
    try {
      await markPageAsCompleted({ variables: { input: { pageId } } });
      // Re-query the quest data after marking the page as completed
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Failed to mark page as completed. Please try again!",
      });
    }
  };

  const handleMarkTaskAsCompleted = useCallback(
    async (task: Task) => {
      try {
        const taskId = task.id;
        if (!taskId) return;

        await toggleTaskCompletion({
          variables: { input: { taskId } },
          optimisticResponse: {
            toggleTaskCompletion: {
              __typename: "ToggleTaskCompletionPayload",
              successful: true,
              result: {
                id: Number(task.id),
                completed: !task.completed,
                task: {
                  __typename: "Task",
                  id: task.id,
                  completed: !task.completed,
                },
              },
            },
          },
        });
      } catch (error) {
        toast({
          variant: "destructive",
          description: "Failed to mark task as completed. Please try again!",
        });
      }
    },
    [toggleTaskCompletion, toast]
  );
  return {
    quest: questRaw?.b2bQuestBySlugAndLanguage,
    questLoading,
    questDataError,
    handleEnrollUser,
    handleMarkPageAsCompleted,
    handleMarkTaskAsCompleted,
    enrollLoading,
    markPageLoading,
    markTaskLoading,
    enrollError,
    markPageError,
    markTaskError,
  };
};

export default useQuestMutations;
