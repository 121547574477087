import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Hls from "hls.js";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import type { Caption } from "types/__generated__/graphql";

interface MediaPlayerProps {
  mediaUrl?: string;
  thumbnailUrl?: string;
  autoplay?: boolean;
  muted?: boolean;
  looped?: boolean;
  showVControls?: boolean;
  showOpacity?: boolean;
  playerClasses?: string;
  videoClasses?: string;
  playOnHover?: boolean;
  pauseOnLeave?: boolean;
  autoplayVisible?: boolean;
  mediaType?: "video" | "audio";
  captions?: Caption[];
}

export interface MediaPlayerHandle {
  play: () => Promise<void>;
  pause: () => void;
  mute: (isMuted: boolean) => void;
  toggleCaptions: (showCaptions: boolean) => void;
  enterFullScreen: () => void;
  getVideoElement: () => HTMLVideoElement | HTMLAudioElement | null;
}

export const MediaPlayer = forwardRef<MediaPlayerHandle, MediaPlayerProps>(
  (
    {
      mediaUrl,
      thumbnailUrl,
      autoplay = false,
      muted = false,
      looped = false,
      showVControls = true,
      showOpacity = false,
      playerClasses,
      videoClasses,
      pauseOnLeave = false,
      playOnHover = false,
      autoplayVisible = false,
      mediaType = "video",
      captions = [],
    }: MediaPlayerProps,
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const hlsRef = useRef<Hls | null>(null);
    const [isPlaying, setIsPlaying] = useState(autoplay);

    // Expose methods to the parent component
    useImperativeHandle(ref, () => ({
      play: async () => {
        if (videoRef.current) {
          await videoRef.current.play();
          setIsPlaying(true);
        }
      },
      pause: () => {
        if (videoRef.current) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      },
      mute: (isMuted: boolean) => {
        if (videoRef.current) {
          videoRef.current.muted = isMuted;
        }
      },
      toggleCaptions: (showCaptions: boolean) => {
        const tracks = videoRef.current?.textTracks;
        if (tracks?.[0]) {
          tracks[0].mode = showCaptions ? "showing" : "hidden";
        }
      },
      enterFullScreen: () => {
        const container = videoRef.current;
        if (container?.isConnected) {
          container
            .requestFullscreen()
            .then(() => {
              console.log("Entered fullscreen successfully.");
            })
            .catch((error) => {
              console.error("Failed to enter fullscreen:", error);
            });
        } else {
          console.error("Container is not connected to the DOM.");
        }
      },
      getVideoElement: () => videoRef.current,
    }));

    // Initialize HLS and handle video events
    useEffect(() => {
      const video = videoRef.current;

      if (!video) {
        console.error("Video element is not available.");
        return;
      }

      // Cleanup existing HLS instance if any
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }

      const initializeHls = () => {
        if (Hls.isSupported() && mediaUrl?.endsWith(".m3u8")) {
          const hls = new Hls({
            enableWorker: true,
            lowLatencyMode: true,
          });

          hlsRef.current = hls;

          hls.loadSource(mediaUrl);
          hls.attachMedia(video);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log("HLS manifest parsed successfully.");
            if (autoplay) {
              video.play().catch((error) => {
                console.error("Auto-play was prevented:", error);
              });
            }
          });

          hls.on(Hls.Events.ERROR, (_, data) => {
            console.error("HLS error:", data);
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error("HLS network error, retrying...");
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.error("HLS media error, recovering...");
                  hls.recoverMediaError();
                  break;
                default:
                  console.error("Fatal HLS error, destroying instance...");
                  hls.destroy();
                  break;
              }
            }
          });
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          // Native HLS support (e.g., Safari)
          console.log("Using native HLS support.");
          video.src = mediaUrl || "";
          video.load();
          if (autoplay) {
            video.play().catch((error) => {
              console.error("Auto-play was prevented:", error);
            });
          }
        } else {
          console.error("HLS is not supported in this browser.");
          // Fallback to MP4
          video.src = mediaUrl || "";
          video.load();
          if (autoplay) {
            video.play().catch((error) => {
              console.error("Auto-play was prevented:", error);
            });
          }
        }
      };

      initializeHls();

      // Add event listeners for play/pause
      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy();
          hlsRef.current = null;
        }
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      };
    }, [mediaUrl, autoplay]);

    return (
      <div className="relative w-full h-auto">
        <video
          ref={videoRef}
          poster={thumbnailUrl}
          muted={muted}
          loop={looped}
          controls={false}
          className={cn("w-full h-auto", videoClasses)}
          controlsList="nodownload"
          autoPlay={autoplay && !autoplayVisible}
        >
          Your browser does not support the video element.
          <source src={mediaUrl} type="video/mp4" />
          {captions.map((caption) => (
            <track
              key={caption.id}
              src={caption.url}
              kind="captions"
              srcLang={caption.language}
              label={caption.label}
            />
          ))}
        </video>
      </div>
    );
  }
);

MediaPlayer.displayName = "MediaPlayer";
