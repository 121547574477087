import { Tooltip } from "components/ui/tooltip";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import useMediaPlayerStore from "stores/mediaPlayerStore";
// import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import { cn } from "libs/classMerger";

export const MediaPlayerMainControls = () => {
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  // const fullScreenMode = useMediaPlayerUIStore((state) => state.fullScreenMode);
  const timerInfinity = useMediaPlayerStore((state) => state.timerInfinity);
  const { handleForward, handleRewind, onPlayPause } = useMediaPlayerHook();

  return (
    <div className="flex items-center gap-4">
      {!timerInfinity && (
        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">15 sec rewind</p>}
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => handleRewind()}
          >
            <Icons name="rewind-15-outlined" width={24} height={24} />
          </Button>
        </Tooltip>
      )}

      <Tooltip
        delayDuration={0}
        tooltipContent={
          <p className="caption-disclaimer">{isPlaying ? "Pause" : "Play"}</p>
        }
      >
        <Button
          variant="icon"
          size="icon"
          className={cn(
            "text-white/70 hover:text-white transition-all duration-500 size-10"
            // fullScreenMode && "size-14"
          )}
          onClick={onPlayPause}
        >
          <Icons
            name={isPlaying ? "pause-circle-filled" : "play-circle-filled"}
            className="size-full"
          />
        </Button>
      </Tooltip>

      {!timerInfinity && (
        <Tooltip
          delayDuration={0}
          tooltipContent={<p className="caption-disclaimer">15 sec forward</p>}
        >
          <Button
            variant="icon"
            size="icon"
            className="text-white/70 hover:text-white"
            onClick={() => handleForward()}
          >
            <Icons name="forward-15-outlined" width={24} height={24} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
