import { ProgressBar } from "components/ProgressBar";
import Icons from "components/icons/Icons";
import { Label } from "components/ui/label";
import { Tooltip } from "components/ui/tooltip";
import { useNavigate } from "react-router-dom";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { B2bQuest } from "types/__generated__/graphql";

// Taken from Darshan work on Card component

// web view
function DetailsView({
  quest,
  endDate,
  isDueSoon,
  formattedDueDate,
  totalDaysOrLessonsCompleted,
  currentLessonDuration,
  handleClickPlayIcon,
}: {
  quest: B2bQuest;
  endDate?: string;
  isDueSoon: boolean;
  formattedDueDate: string;
  totalDaysOrLessonsCompleted?: number;
  currentLessonDuration: number;
  handleClickPlayIcon: () => void;
}) {
  const { name, userProgress, type } = quest;
  return (
    <div className="lg:flex items-start  flex-col w-full justify-between lg:px-6 lg:py-4 gap-3  hidden">
      <div className="flex flex-col gap-y-4">
        <div className="md:p-0">
          <p className="text-start caption-disclaimer py-1">Quests</p>
          <p className="lg:heading-8 md:title-9 text-start">{name}</p>
          <span className="flex justify-between gap-2 w-fit title-10">
            <p>lesson {(totalDaysOrLessonsCompleted ?? 0) + 1}</p>
            <p>•</p>
            <p>{currentLessonDuration} mins</p>
          </span>
        </div>
        {endDate && (
          <Label className="hidden lg:flex w-fit gap-2 bg-cool-grey-250 rounded p-1 ">
            <Icons
              name="calendar-reminder-outlined"
              width={14}
              height={14}
              fill="black"
            />
            <p className={`title-10 ${isDueSoon ? "text-red-500" : ""}`}>
              Complete by {formattedDueDate}
            </p>
          </Label>
        )}
      </div>
      <div className="flex w-full justify-between">
        <span className="flex flex-col w-4/5 justify-center items-start">
          <ProgressBar
            value={
              type === "daily"
                ? userProgress?.totalDaysCompleted
                : userProgress?.totalLessonsCompleted
            }
            maxValue={
              type === "daily"
                ? userProgress?.totalDays
                : userProgress?.totalLessons
            }
            containerClasses="my-2"
            progressColor="bg-yellow-500"
          />
          <p className="title-10">
            {type === "daily"
              ? userProgress?.totalDaysCompleted
              : userProgress?.totalLessonsCompleted}{" "}
            / {type ? userProgress?.totalDays : userProgress?.totalLessons}{" "}
            lessons completed
          </p>
        </span>
        <div className="flex gap-2">
          <button
            className="bg-red-600 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gradient-to-b from-red-400 to-red-600"
            onClick={handleClickPlayIcon}
            type="button"
          >
            <Icons name="play-filled" width={20} height={21} fill="white" />
          </button>
          <Tooltip
            tooltipContent={<p className="caption-disclaimer">Learn more</p>}
          >
            <button
              className=" border border-cool-grey-250 rounded-full w-9 h-9 flex items-center justify-center hover:bg-cool-grey-200"
              type="button"
            >
              <QuestLandingPage
                questArgs={quest}
                trigger={
                  <Icons
                    name="list-filled"
                    height={20}
                    width={20}
                    fill="#595E67"
                  />
                }
              />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

// mobile progress bar container
function ProgressElementMobile({
  quest,
  handleClickPlayIcon,
}: {
  quest: B2bQuest;
  handleClickPlayIcon: () => void;
}) {
  const { userProgress, type } = quest;
  return (
    <div className="lg:hidden flex w-full justify-between p-[6px] items-center gap-10 ">
      <span className="flex flex-col lg:w-3/5 w-full justify-center items-start">
        <ProgressBar
          value={
            type === "daily"
              ? userProgress?.totalDaysCompleted
              : userProgress?.totalLessonsCompleted
          }
          maxValue={
            type === "daily"
              ? userProgress?.totalDays
              : userProgress?.totalLessons
          }
          containerClasses="my-2"
          progressColor="bg-yellow-500"
        />
        <p className="title-10">
          {type === "daily"
            ? userProgress?.totalDaysCompleted
            : userProgress?.totalLessonsCompleted}{" "}
          / {type ? userProgress?.totalDays : userProgress?.totalLessons}{" "}
          lessons completed
        </p>
      </span>
      <div className="flex gap-2">
        <button
          className="bg-red-600 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gradient-to-b from-red-400 to-red-600"
          onClick={handleClickPlayIcon}
          type="button"
        >
          <Icons name="play-filled" width={20} height={21} fill="white" />
        </button>
        <button
          className=" border border-cool-grey-250 rounded-full w-9 h-9 flex items-center justify-center"
          type="button"
        >
          <QuestLandingPage
            questArgs={quest}
            trigger={
              <Icons name="list-filled" height={20} width={20} fill="#595E67" />
            }
          />
        </button>
      </div>
    </div>
  );
}

// mobile chapter/detaile view container
function DetailsViewMobile({
  quest,
  endDate,
  formattedDueDate,
  currentLessonDuration,
}: {
  quest: B2bQuest;
  endDate?: string;
  formattedDueDate: string;
  currentLessonDuration: number;
}) {
  return (
    <div className="lg:hidden block px-4 ">
      <div className="md:p-0 ">
        <p className=" title-9 text-start line-clamp-2	  ">{quest.name}</p>
        <span className="flex justify-between gap-2 w-fit title-10">
          <p>
            lessonss{" "}
            {(quest.type === "daily"
              ? quest.userProgress?.totalDaysCompleted ?? 0
              : quest.userProgress?.totalLessonsCompleted ?? 0) + 1}
          </p>
          <p>•</p>
          <p> {currentLessonDuration} mins</p>
        </span>
      </div>
      {endDate && (
        <Label className="hidden lg:flex w-fit gap-2 bg-cool-grey-250 rounded p-1 ">
          <Icons
            name="calendar-reminder-outlined"
            width={14}
            height={14}
            fill="black"
          />
          <p className="title-10">Complete by {formattedDueDate}</p>
        </Label>
      )}
    </div>
  );
}

export function CardVariant3({
  quest,
  endDate,
}: {
  quest: B2bQuest;
  endDate?: string;
}) {
  const navigate = useNavigate();

  const dueDateObj = endDate ? new Date(endDate) : null;

  // Ensure the date is valid
  // const isValidDate = dueDateObj instanceof Date && !isNaN(dueDateObj.getTime());
  const isValidDate =
    dueDateObj instanceof Date && !Number.isNaN(dueDateObj.getTime());

  // Get the current date
  const currentDate = new Date();

  // Check if the due date is valid before performing calculations
  const differenceInDays = isValidDate
    ? (dueDateObj.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
    : Number.POSITIVE_INFINITY; // If the date is invalid, set a high number to prevent 'due soon' status

  // Check if the due date is within the next 30 days
  const isDueSoon = isValidDate && differenceInDays < 30;

  // Format the date safely if it's valid
  const formattedDueDate = isValidDate
    ? dueDateObj.toDateString()
    : "Invalid date";

  function handleClickContinue() {
    const pageId = quest.userProgress?.resumePage?.id;
    const url = pageId
      ? `/quests/${quest.language}/${quest.slug}?pageId=${pageId}`
      : `/quests/${quest.language}/${quest.slug}`;
    navigate(url);
  }

  const totalDaysOrLessonsCompleted =
    quest.type === "daily"
      ? quest.userProgress?.totalDaysCompleted
      : quest.userProgress?.totalLessonsCompleted;

  const currentLessonDuration = Math.round(
    (quest.userProgress?.resumePage?.duration ?? 0) / 60
  );

  return (
    <div className="flex w-full rounded-xl border border-cool-grey-250  hover:shadow-light">
      <div className=" flex flex-col lg:w-fit w-full">
        <div className="flex border-b border-cool-grey-250  items-center ">
          <img
            src={quest.coverAsset?.url}
            alt="quests"
            className="lg:max-w-[302px] lg:w-[302px] lg:h-[170px] lg:max-h-[170px] md:max-w-[200px] md:max-h-[112px] md:w-[200px] md:h-[112px] 
                  sm:max-w-[180px] sm:max-h-[100px] w-[180px] h-[100px] object-contain aspect-video  rounded-tl-xl lg:rounded-l-xl"
          />

          <DetailsViewMobile
            quest={quest}
            endDate={endDate}
            formattedDueDate={formattedDueDate}
            currentLessonDuration={currentLessonDuration}
          />
        </div>
        <div className="lg:hidden p-[6px] ">
          <ProgressElementMobile
            quest={quest}
            handleClickPlayIcon={handleClickContinue}
          />
        </div>
      </div>

      <DetailsView
        quest={quest}
        totalDaysOrLessonsCompleted={totalDaysOrLessonsCompleted}
        endDate={endDate}
        isDueSoon={isDueSoon}
        formattedDueDate={formattedDueDate}
        currentLessonDuration={currentLessonDuration}
        handleClickPlayIcon={handleClickContinue}
      />
    </div>
  );
}
