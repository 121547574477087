import useCuratedMeditationSeries from "hooks/useCuratedMeditationSeries";
import { useNavigate } from "react-router-dom";
import MeditationSeriesListCardView from "../components/MeditationSeriesListCardView";
import { NavigationEnum } from "router/navigationType";

export default function MeditationsSeriesListView() {
  const { curatedData } = useCuratedMeditationSeries();
  const navigate = useNavigate();

  return (
    <div className="grid space-y-6 mt-4">
      {curatedData.map((curated) => (
        <MeditationSeriesListCardView
          key={curated.id}
          handleClick={() => navigate(`${NavigationEnum.AppInMeeting}/meditationSeriesSidePanelView/${curated.slug}`)}
          curated={curated}
        />
      ))}
    </div>
  );
}
