import { useState } from "react";
import { useShallow } from "zustand/shallow";
import { cn } from "libs/classMerger";
import { MediaPlayerHeader } from "./MediaPlayerHeader";
import MediaPlayerV2VideoController from "./MediaPlayerV2VideoController";
import MediaPlayerV2InfoBar from "./MediaPlayerV2InfoBar";
import MediaPlayerV2Controls from "./MediaPlayerV2Controls";
import { MediaMiniPlayerControls } from "./MediaMiniPlayerControls";
import { useMediaPlayerRefs } from "context/MediaPlayerRefsContext";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { getRendition } from "utils/mediaHelpers";

// Test
import { MediaPlayerTrackInfo } from "./MediaPlayerTrackInfo";
import { MediaPlayerMediaController } from "./MediaPlayerMediaController";
import { MediaPlayerController } from "./MediaPlayerController";
import MediaPlayerV2SeekBar from "./MediaPlayerV2SeekBar";
import Icons from "../icons/Icons";
import { Button } from "../Button";
import { Tooltip } from "../ui/tooltip";
import { MediaPlayerVolumeControl } from "./MediaPlayerVolumeControl";
import { MediaPlayerTimerModal } from "./MediaPlayerTimerModal";
import { MediaPlayerUtils } from "./controls/MediaPlayerUtils";
import { MediaPlayerMainControls } from "./controls/MediaPlayerMainControls";

const MediaPlayerV2 = () => {
  const { videoPlayerRef } = useMediaPlayerRefs();

  // const [hideControls, setHideControls] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  // const [showControlBar, setShowControlBar] = useState(false);

  const { track } = useMediaPlayerStore((state) => state);
  const { fullScreenMode, show, isTimerSet } = useMediaPlayerUIStore(
    useShallow((state) => ({
      isTimerSet: state.isTimerSet,
      fullScreenMode: state.fullScreenMode,
      show: state.show,
      soundscapeMode: state.soundscapeMode,
    }))
  );

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  //   setShowControlBar(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowControlBar(false);
  // };

  // Sync player context updates
  // useEffect(() => {
  //   return () => {
  //     if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);
  //   };
  // }, [show, fullScreenMode, track?.coverAsset.url]);

  // Mouse movement logic
  // const handleMouseMove = () => {
  //   setHideControls(false);

  //   if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);

  //   hideControlsTimer.current = setTimeout(() => {
  //     setHideControls(true);
  //   }, 5000); // 5 seconds
  // };

  // const handleMouseEnter = () => {
  //   if (hideControlsTimer.current) clearTimeout(hideControlsTimer.current);
  //   setHideControls(false);
  // };

  //@ts-ignore - Due to improper DIY typings
  // const rendition =
  //   track?.subtype === "background" // TODO:: temporary fix
  //     ? getVideoRendition(
  //         Array.isArray(track?.trailerLoopingAsset?.renditions)
  //           ? track?.trailerLoopingAsset?.renditions
  //           : []
  //       )
  //     : null;

  const videoRendition = getRendition(
    track?.trailerLoopingAsset?.renditions || [],
    ["application/x-mpegURL", "video/mp4"]
  );

  return (
    <>
      {/* I think not conditional rendering is fine */}
      <div
        className={cn(
          "group w-full z-[100] bg-black/90 backdrop-blur-md flex-col items-center inline-flex fixed bottom-0 transition-opacity duration-1000",
          fullScreenMode && "h-screen",
          show
            ? "opacity-100 translate-y-0"
            : "opacity-0 translate-y-full pointer-events-none"
        )}
        aria-hidden={!show}
        // onMouseMove={handleMouseLeave}
        // onMouseEnter={handleMouseEnter}
      >
        {fullScreenMode && (
          <MediaPlayerHeader title="Now Playing" subtitle="Soundscape" />
        )}

        {/* Video Controller */}

        <div className={cn(fullScreenMode && "h-screen w-full relative")}>
          {fullScreenMode && (
            <MediaPlayerV2VideoController
              videoPlayerRef={videoPlayerRef}
              src={videoRendition?.src}
              poster={track?.coverAsset?.url || ""}
              muted={true}
              loop={isTimerSet}
              autoPlay
              // cssClass={`${
              //   fullScreenMode ? "animate-slideInBottom" : "animate-slide-out-bottom"
              // }`}
            />
          )}
        </div>

        <div
          className={cn(
            "w-full z-50",
            fullScreenMode &&
              "mt-auto absolute bottom-0 lg:media-container-fullscreen-hover-transition"
          )}
        >
          <div
            className={cn(
              " w-full px-4 py-3 flex items-center justify-between gap-y-4 bg-black",
              fullScreenMode &&
                "flex flex-col bg-transparent bg-gradient-to-t from-black/90 py-[34px]"
            )}
          >
            <div
              className={cn(
                "w-full absolute top-0 left-0",
                fullScreenMode && "static order-2"
              )}
            >
              <MediaPlayerV2SeekBar
                showDuration={false}
                className={cn(
                  "w-full flex items-center gap-4 z-20",
                  fullScreenMode ? "" : "lg:absolute g:left-0 lg:top-0"
                )}
              />
            </div>

            <div className={cn(fullScreenMode && "order-first w-full")}>
              <MediaPlayerTrackInfo />
            </div>

            <div className={cn(fullScreenMode && "w-full order-last relative")}>
              <MediaPlayerUtils />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPlayerV2;
