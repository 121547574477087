import { useNavigate } from "react-router-dom";
import { Carousel, CarouselContent, CarouselItem } from "components/Carousel";
import { QuestMediationLanding } from "./QuestMeditationLanding";
import { Button } from "components/Button";
import useScreenType from "hooks/useScreenType";
import type { B2bQuest } from "types/__generated__/graphql";

export const MyQuestMeditationsSection = ({
  data,
}: {
  data: B2bQuest[] | null;
}) => {
  const navigate = useNavigate();
  const screenType = useScreenType();

  const filterQuestsWithResources = (quests: B2bQuest[]): B2bQuest[] => {
    return quests.filter(
      (quest) => quest.questResources && quest.questResources.length > 0
    );
  };

  const isMobile = screenType.isMobile || screenType.isTablet;

  if (!data) return null;

  return (
    <section className="flex flex-col">
      <div className="flex justify-between items-center">
        <div className="heading-6">My Quest meditations</div>
        <Button
          variant="ghost"
          onClick={() => navigate("/library/ongoing")}
          className="flex"
        >
          View All
        </Button>
      </div>
      <div>
        <div className="flex w-full overflow-hidden">
          <Carousel
            opts={{
              align: "start",
              active: isMobile,
            }}
            orientation="horizontal"
            className="w-full"
          >
            <CarouselContent>
              {filterQuestsWithResources(data)
                .slice(0, 3)
                ?.map((quest) => (
                  <CarouselItem
                    key={quest?.id}
                    className="basis-1/2 lg:basis-1/3"
                  >
                    <div className="relative">
                      <img
                        src={quest.headshotCoverAsset?.url}
                        alt={quest.name}
                        className="size-full rounded-2xl"
                      />
                      <div className="flex w-full items-center absolute rounded-b-2xl bottom-0 p-4 gap-2 backdrop-blur-[6px] bg-black/40">
                        <img
                          src={quest.coverAsset?.thumbnailUrl}
                          alt="thumbnail"
                          className="rounded-lg h-[48px]"
                        />
                        <div className="flex flex-col w-full items-center">
                          <h3 className="title-8 text-white w-full line-clamp-1">
                            {quest.name}
                          </h3>
                          <h3 className="caption-disclaimer text-cool-grey-300 w-full">
                            {quest.questResources.length}{" "}
                            {quest.questResources.length > 1
                              ? "tracks"
                              : "track"}
                          </h3>
                        </div>
                        <QuestMediationLanding quest={quest} />
                      </div>
                    </div>
                  </CarouselItem>
                ))}
            </CarouselContent>
          </Carousel>
        </div>
      </div>
    </section>
  );
};
