import { useUserState } from "context/userContext";
import {
  useGetQuestsFromLibraryQuery,
  QuestMembershipFilterValues,
  type GetQuestsFromLibraryQueryVariables,
  type B2bQuest,
} from "types/__generated__/graphql";

function useLibrary(variables: GetQuestsFromLibraryQueryVariables) {
  const { isAuthenticated } = useUserState();
  // const { loading, error, data } = useQuery(GET_LIBRARY, {
  //   skip: !isAuthenticated,
  //   variables: {
  //     first,
  //     status,
  //     membership,
  //   },
  // });
  const { loading, error, data } = useGetQuestsFromLibraryQuery({
    skip: !isAuthenticated,
    variables: {
      ...variables,
      membership: QuestMembershipFilterValues.All,
    },
  });

  const quests = data?.b2bQuests?.edges?.map((edge) => edge?.node) as
    | B2bQuest[]
    | null;

  return { data: quests, loading, error };
}

export default useLibrary;
