import { Spinner } from "@fluentui/react-components";
import type { Theme } from "@fluentui/react-components";
import Layout from "layouts/Layout";
import { ScrollToHashElement } from "libs/scrollToHashElement";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Index from "tabs/Index";
import HomeTab from "tabs/Home/Home";
import CompanyNameForm from "tabs/HubspotForm/CompanyNameForm";
import FormSubmitLoadingScreen from "tabs/HubspotForm/FormSubmitLoadingScreen";
import InterestForm from "tabs/HubspotForm/InterestForm";
import ThankYouScreen from "tabs/HubspotForm/ThankYouScreen";

import { Profile, TabPage } from "tabs/Profile/Profile";
import ProgramsTab from "tabs/Programs/Programs";
import QuestConsumption from "tabs/Quest/QuestConsumption/QuestConsumption";
// import ShareToMeeting from "tabs/Config/ShareToMettingConfig";
import AppInMeetingView from "tabs/Sidepanel/AppInMeeting";
import MeditationSeriesDetailsSidePanelView from "tabs/Sidepanel/views/MeditationSeriesDetailsSidePanelView";
import StageView from "tabs/StageView";
import Soundscapes from "tabs/Soundscapes/Soundscapes";
import SoundscapesCollectionsView from "tabs/Soundscapes/views/SoundscapesCollectionsView";
import SoundscapesCategoriesView from "tabs/Soundscapes/views/SoundscapesCategoriesView";
import Meditations from "tabs/Meditations/Meditations";
import MeditationsCollectionsView from "tabs/Meditations/views/MeditationsCollectionsView";
import MeditationsCategoriesView from "tabs/Meditations/views/MeditationsCategoriesView";
import Configure from "utils/Configure";
import { NavigationEnum } from "./navigationType";
import MessageHandler from "../components/MessageHandler";


export default function AppRoute({
  loading,
  themeString,
  theme,
}: {
  loading: any;
  themeString: string;
  theme?: Theme;
}) {
  return (
    <>
      {loading ? (
        <Spinner style={{ margin: 100 }} />
      ) : (
        <BrowserRouter>
          <ScrollToHashElement />
          <Layout themeString={themeString} theme={theme}>
            <Routes>
              {/* <Route path="/" element={<Index />} /> */}
              {/* <Route path="/" element={<ShareToMeeting />} /> */}
              <Route path={NavigationEnum.Home} element={<HomeTab />} />
              <Route path={NavigationEnum.Configure} element={<Configure />} />
              <Route path={NavigationEnum.Programs} element={<ProgramsTab />} />

              <Route path={NavigationEnum.Soundscapes}>
                <Route index element={<Soundscapes />} />
                <Route
                  path={`${NavigationEnum.Soundscapes}/collections`}
                  element={<SoundscapesCollectionsView />}
                />
                <Route
                  path={`${NavigationEnum.Soundscapes}/categories`}
                  element={<SoundscapesCategoriesView />}
                />
                <Route
                  path={`${NavigationEnum.Soundscapes}/categories/:slug`}
                  element={<SoundscapesCategoriesView />}
                />
              </Route>

              <Route path={NavigationEnum.Meditations}>
                <Route index element={<Meditations />} />
                <Route
                  path={`${NavigationEnum.Meditations}/collections`}
                  element={<MeditationsCollectionsView />}
                />
                <Route
                  path={`${NavigationEnum.Meditations}/categories`}
                  element={<MeditationsCategoriesView />}
                />
                <Route
                  path={`${NavigationEnum.Meditations}/categories/:slug`}
                  element={<MeditationsCategoriesView />}
                />
              </Route>

              <Route path={NavigationEnum.StageView} element={<StageView />} />
              <Route
                path={NavigationEnum.AppInMeeting}
                element={<AppInMeetingView />}
              />
              <Route
                path={`${NavigationEnum.AppInMeeting}/meditationSeriesSidePanelView/:slug`}
                element={<MeditationSeriesDetailsSidePanelView />}
              />
              <Route path={NavigationEnum.Quests}>
                <Route path={":lang/:slug"} element={<QuestConsumption />} />
                <Route
                  path={":lang/:slug/:groupId/:pageId"}
                  element={<QuestConsumption />}
                />
                <Route
                  path={":lang/:slug/:groupId/:pageId/:tmp"}
                  element={<QuestConsumption />}
                />
              </Route>
              <Route
                path={NavigationEnum.Account}
                element={<Profile page={TabPage.MyAccount} />}
              >
                <Route
                  path={"/account/:section"}
                  element={<Profile page={TabPage.MyAccount} />}
                />
              </Route>
              <Route
                path={NavigationEnum.Account}
                element={<Profile page={TabPage.MyAccount} />}
              >
                <Route
                  path={"/account/:section"}
                  element={<Profile page={TabPage.MyAccount} />}
                />
              </Route>
              <Route
                path={NavigationEnum.Library}
                element={<Profile page={TabPage.MyLibrary} />}
              >
                <Route
                  path={"/library/:section"}
                  element={<Profile page={TabPage.MyLibrary} />}
                />
              </Route>
              <Route
                path={NavigationEnum.InterestForm}
                element={<InterestForm />}
              />
              <Route
                path={NavigationEnum.ThankyouScreen}
                element={<ThankYouScreen />}
              />
              <Route
                path={NavigationEnum.CompanyForm}
                element={<CompanyNameForm />}
              />
              <Route path="/loading" element={<FormSubmitLoadingScreen />} />
            </Routes>
          </Layout>
          <MessageHandler />
        </BrowserRouter>
      )}
    </>
  );
}
