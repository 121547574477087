import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Button } from "components/Button";
import { Combobox } from "components/ComboBox";
import { CenteredOverlaySpinner } from "components/Spinner";
import { TagInput } from "components/TagInput";
import Icons from "components/icons/Icons";
import { useToast } from "hooks/use-toast";
import useCityList from "hooks/useCityList";
import useLanguages from "hooks/useLanguages";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import gqlQuery from "services/graphql/queries";
import type {
  ICurrentProfile,
  IOption,
  ISpokenLanguage,
  IUpdateProfileInput,
} from "types/interfaces";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/Form";
import { Textarea } from "../../components/Textarea";
import { Tooltip } from "../../components/Tooltip";
import { ProfileCard } from "../Profile/ProfileCard";

export const EditProfile = ({
  onBackBtnClick,
  profileData,
}: {
  onBackBtnClick: () => void;
  profileData?: ICurrentProfile;
}) => {
  const DiscardChangesDialog = ({
    open = false,
    onConfirm,
    onCancel,
  }: {
    open?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  }) => {
    return (
      <Dialog open={open}>
        <DialogContent className="z-[100] p-10 flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>
              <div className="title-6">Discard changes</div>
            </DialogTitle>
          </DialogHeader>
          <p className="body text-cool-grey-600">
            You have made some changes. Do you want to discard them?
          </p>
          <DialogFooter className="flex">
            <Button
              size={"link"}
              className="py-3 px-[14px]"
              onClick={onConfirm}
              variant={"neutral"}
              type="submit"
            >
              Discard
            </Button>
            <Button
              size={"sm"}
              className="py-3 px-[14px]"
              onClick={onCancel}
              variant={"ghost"}
              type="submit"
            >
              Back to edit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const [searchKey, setSearchKey] = useState(""); // <-- Search key state
  const [location, setLocation] = useState<string>("");

  const {
    cityData,
    loading: loadingCities,
    error: getCitiesError,
  } = useCityList(100, searchKey);

  const onCitySelect = (selected: IOption) => {
    if (selected) {
      setLocation(selected.name);
      form.setValue("location", selected.name, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  };

  const {
    data: langData,
    loading: loadingLanguages,
    error: getLanguagesError,
  } = useLanguages({
    first: 100,
  });

  const onLangsChange = (selected: IOption[]) => {
    form.setValue(
      "spokenLanguages",
      selected?.map((lang) => Number.parseInt(lang.value)),
      {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      }
    );
  };

  const { toast } = useToast();

  const formSchema = z.object({
    bio: z.string(),
    location: z.string(),
    avatarUrl: z.string(),
    spokenLanguages: z.array(z.number()),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bio: profileData?.bio || "",
      location: profileData?.location || "",
      spokenLanguages:
        profileData?.spokenLanguages?.map((lang) => lang?.id) || [],
    },
  });

  const watchBio = form.watch("bio", "");

  const resetForm = () => {
    form.reset();
    onBackBtnClick();
  };

  const [
    updateProfile,
    { loading: updatingProfile, error: updateProfileError },
  ] = useMutation(gqlQuery.updateCurrentProfile);

  useEffect(() => {
    if (!updateProfileError) return;
    toast({
      variant: "destructive",
      description: `Error occurred while updating profile: ${updateProfileError}`,
    });
  }, [updateProfileError]);

  const onUpdateProfile = async (values: z.infer<typeof formSchema>) => {
    const input = {
      bio: values.bio,
      location: values.location,
      spokenLanguagesIds: values.spokenLanguages,
    } as IUpdateProfileInput;
    try {
      await updateProfile({
        variables: {
          input,
        },
      });
      toast({
        description: "Profile details updated successfully",
      });
      form.reset();
      onBackBtnClick();
    } catch (e) {
      toast({
        variant: "destructive",
        description: "Failed to update profile details",
      });
    }
  };

  const [openDiscardChangesModal1, showDiscardChangesModal1] = useState(false);
  const [openDiscardChangesModal2, showDiscardChangesModal2] = useState(false);

  const navigateBack = () => {
    if (form.formState.isDirty) {
      showDiscardChangesModal1(true);
    } else {
      onBackBtnClick();
    }
  };

  const onCancelForm = () => {
    if (form.formState.dirtyFields.bio) {
      showDiscardChangesModal2(true);
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    console.log("formState", form.formState);
  }, [form.formState]);

  return (
    <div className="flex flex-col gap-8">
      <CenteredOverlaySpinner show={updatingProfile} />
      <div className="flex gap-2">
        <button type="button" onClick={navigateBack}>
          <Icons
            name="chevron-left-filled"
            width={24}
            height={24}
            fill="#979CA5"
          />
        </button>
        <div className="heading-5">Edit profile</div>
      </div>
      <div className="flex lg:hidden">
        <ProfileCard profileData={profileData} editProfile={true} />
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onUpdateProfile(form.getValues());
          }}
        >
          <div className="flex flex-col gap-6">
            <div className="flex flex-col md:flex-row gap-6 self-stretch">
              <div className="flex flex-col w-full md:w-1/2 gap-2">
                <div className="title-8">First name</div>
                <div className="flex cursor-not-allowed align-middle items-center justify-between gap-3 border py-2 px-[12px] rounded-[8px] border-medium border-cool-grey-200">
                  <div className="h-6 body text-cool-grey-450 border-none focus_ring-0 flex-1">
                    {profileData?.firstName}
                  </div>
                  <Tooltip
                    tooltipContent={
                      <p className="caption-disclaimer">
                        Check with your admin
                      </p>
                    }
                  >
                    <Icons
                      name="lock-filled"
                      height={20}
                      width={20}
                      fill="black"
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="flex cursor-not-allowed w-full md:w-1/2 flex-col gap-2">
                <div className="title-8">Last name</div>
                <div className="flex align-middle items-center justify-between gap-3 border py-2 px-[12px] rounded-[8px] border-medium border-cool-grey-200">
                  <div className="h-6 body text-cool-grey-450 border-none focus_ring-0 flex-1">
                    {profileData?.lastName}
                  </div>
                  <Tooltip
                    tooltipContent={
                      <p className="caption-disclaimer">
                        Check with your admin
                      </p>
                    }
                  >
                    <Icons
                      name="lock-filled"
                      height={20}
                      width={20}
                      fill="black"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col">
                <div className="flex gap-1 items-center">
                  <div className="title-8">About myself</div>
                  <div className="flex">
                    <Tooltip
                      tooltipContent={
                        <div className="caption-disclaimer px-4 py-3">
                          Tips:
                          <ul style={{ listStyleType: "circle" }}>
                            <li>
                              What’s your area of expertise and what are you
                              passionate about in your work?
                            </li>
                            <li>
                              Describe your career journey and your proudest
                              accomplishments so far.{" "}
                            </li>
                            <li>
                              What's your go-to advice for dealing with a
                              stressful situation?{" "}
                            </li>
                            <li>
                              What's one thing you love about working at this
                              company?
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      <InfoCircledIcon fontSize={20} />
                    </Tooltip>
                  </div>
                </div>
                <div className="body-small text-cool-grey-450 pb-2">
                  We recommend at least 100 characters to shine.
                </div>
              </div>
              <FormField
                control={form.control}
                name="bio"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        maxLength={1000}
                        {...field}
                        className="w-full body border border-cool-grey-200"
                        rows={5}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex self-end">
                <p className="text-cool-grey-450 caption-disclaimer text-right">
                  {watchBio.length}/1,000
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="title-8">Location</div>
              <div className="flex w-full rounded-[8px] border border-cool-grey-200">
                <FormField
                  control={form.control}
                  name="location"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Combobox
                          options={cityData?.map((city: any) => ({
                            name: city.name,
                            value: city.country.iso3Code,
                            adminDivision: city.adminDivision?.name,
                          }))}
                          onSelect={onCitySelect}
                          placeholderText="Select your city"
                          onSearch={setSearchKey}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="title-8">Spoken language</div>
              <div className="flex align-middle w-full rounded-[8px] border border-cool-grey-200 items-center">
                <Controller
                  control={form.control}
                  name="spokenLanguages"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <TagInput
                          options={langData?.map(
                            (lang: ISpokenLanguage) =>
                              ({
                                value: lang?.id?.toString(),
                                name: lang?.displayName,
                              } as IOption)
                          )}
                          onChange={onLangsChange}
                          initialValues={profileData?.spokenLanguages?.map(
                            (lang) =>
                              ({
                                value: lang?.iso2Code?.toString(),
                                name: lang?.displayName,
                              } as IOption)
                          )}
                          placeholderText="Add languages"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex gap-1">
              <Button
                variant={form.formState.isValid ? "primary" : "secondary"}
                disabled={!form.formState.isDirty}
                className="py-3.5 px-4 rounded-100 bg-red-600 button-text disabled:bg-black/10 flex items-center justify-center enabled:hover:gradient-red-wave-top-down text-white disabled:text-black/20"
                type="submit"
              >
                Update profile
              </Button>
              <Button
                variant={"ghost"}
                type="reset"
                onClick={onCancelForm}
                className="button-text text-cool-grey-700 py-3.5 px-6 rounded-100 cursor-pointer text-center hover:bg-black-4a"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Form>
      <DiscardChangesDialog
        open={openDiscardChangesModal1}
        onCancel={() => {
          showDiscardChangesModal1(false);
        }}
        onConfirm={() => {
          onBackBtnClick();
          showDiscardChangesModal1(false);
        }}
      />
      <DiscardChangesDialog
        open={openDiscardChangesModal2}
        onCancel={() => {
          showDiscardChangesModal2(false);
        }}
        onConfirm={() => {
          showDiscardChangesModal2(false);
          resetForm();
        }}
      />
    </div>
  );
};
