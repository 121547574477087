import { useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useChannelBySlug from "hooks/useChannelBySlug";
import useCollections from "hooks/useCollections";
import { DetailCategoriesHeroBanner } from "components/soundscapesAndMeditations/DetailCategoriesHeroBanner";
import { CollectionsListGridSection } from "../../../components/soundscapesAndMeditations/CollectionsListGridSection";
import {
  CollectionHighlightStatusFilterEnum,
  SeriesPublishStatusFilterEnum,
  ResourceFeaturedStatusFilterEnum,
  ResourceStatusFilterEnum,
  ResourceTypeFilterEnum,
  ResourceSortByEnum,
} from "types/__generated__/graphql";

const headerButtonArray = [
  "All",
  "Scenarios",
  "Authors",
  "Languages",
  "Others",
];

const categoryToChannelIdentifiers: { [key: string]: string[] } = {
  All: [
    "meditation-collections",
    "b2b-meditations-collections-scenarios",
    "b2b-meditations-collections-authors",
    "b2b-meditations-collections-languages",
    "b2b-meditations-collections-other",
  ],
  Scenarios: ["b2b-meditations-collections-scenarios"],
  Authors: ["b2b-meditations-collections-authors"],
  Languages: ["b2b-meditations-collections-languages"],
  Others: ["b2b-meditations-collections-other"],
};

export default function MeditationsCollectionsView() {
  const navigate = useNavigate();
  const exploreCollectionsRef = useRef<HTMLDivElement | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const currentChannelIdentifiers = useMemo(() => {
    return (
      categoryToChannelIdentifiers[selectedCategory] ||
      categoryToChannelIdentifiers.All
    );
  }, [selectedCategory]);

  const { data, loading: fetchingData } = useChannelBySlug({
    slug: "b2b-meditations-collections-scenarios",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
  });

  const { data: collectionsData, loading: fetchingCollections } =
    useCollections({
      channelIdentifiers: currentChannelIdentifiers,
      status: SeriesPublishStatusFilterEnum.All,
      first: 20,
      limit: 20,
      resourceFeaturedStatus: ResourceFeaturedStatusFilterEnum.All,
      resourceStatus: ResourceStatusFilterEnum.All,
      type: ResourceTypeFilterEnum.Meditation,
      sortBy: ResourceSortByEnum.Title,
    });

  return (
    <div className="flex flex-col gap-10 container mx-auto">
      <DetailCategoriesHeroBanner
        copy={{
          title: "Meditation Collections",
          description:
            "Experience the transformative benefits of meditation. Explore curated collections of meditations tailored to your specific needs, whether it's starting your day with clarity or winding down for a restful night's sleep.",
          outerNavigationCTA: "Explore categories",
        }}
        bannerVideoUrl={data?.trailerLoopingAsset?.url}
        isFetching={fetchingData || !data}
        handleInnerNavigation={() => {
          exploreCollectionsRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
        handleOuterNavigation={() => navigate("/meditations/categories")}
      />

      <CollectionsListGridSection
        type="meditations"
        showTitle={false}
        headerButtonArray={headerButtonArray}
        copy={{ title: "Soundscape collections" }}
        sectionRef={exploreCollectionsRef}
        isFetching={fetchingCollections}
        collectionsData={collectionsData}
        isCollections
        handleCategoryNavigation={(slug) =>
          navigate(`/meditations/categories/${slug}`)
        }
        selectedCategory={selectedCategory}
        handleChangeCategory={(category) => setSelectedCategory(category)}
      />
    </div>
  );
}
