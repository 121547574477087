// src/components/MediaPlayerV2VideoController.tsx
import type React from "react";
import { useEffect, useRef, useState, memo } from "react";
import { cn } from "libs/classMerger";
import AuroraGPU from "./AuroraCanvas";
import { extractColorsFromImageData } from "libs/imagesUtils";
import useMediaPlayerUIStore from "stores/mediaPlayerUIStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import { ErrorOverlay } from "./controls/MediaPlayerErrorOverlay";
import Hls from "hls.js";

interface MediaPlayerV2VideoControllerProps {
  videoPlayerRef: React.MutableRefObject<HTMLVideoElement | null>;
  src?: string;
  poster: string;
  muted: boolean;
  loop: boolean;
  autoPlay: boolean;
  cssClass?: string;
}

const MediaPlayerV2VideoController: React.FC<
  MediaPlayerV2VideoControllerProps
> = ({ videoPlayerRef, src, poster, muted, loop, autoPlay, cssClass }) => {
  const immersionMode = useMediaPlayerUIStore((state) => state.immersionMode);
  const { onPlayPause } = useMediaPlayerHook();

  const [colors, setColors] = useState<string[]>([
    "#563B94",
    "#B2405F",
    "#00C800",
    "#373C8C",
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(!!src && immersionMode);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const hlsRef = useRef<Hls | null>(null);

  const handleError = (message: string, errorType: string) => {
    console.error(`${errorType}: ${message}`);
    setError(message);
    setIsLoading(false);
  };

  // Fetch colors from poster image for AuroraGPU
  useEffect(() => {
    const fetchColors = async () => {
      try {
        if (poster) {
          const result = await extractColorsFromImageData(poster);
          if (result && result.length > 0) {
            setColors(result);
          }
        }
      } catch (error) {
        console.error("Error extracting colors:", error);
      }
    };

    fetchColors();
  }, [poster]);

  // Initialize HLS.js or native HLS based on immersionMode and src
  useEffect(() => {
    const video = videoPlayerRef.current;

    if (!video) return;

    if (immersionMode && src) {
      setIsLoading(true);
      setIsVideoReady(false);
      setError(null);

      // Destroy existing HLS instance if any
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }

      const initializeHls = () => {
        if (Hls.isSupported()) {
          const hls = new Hls({
            enableWorker: true,
            lowLatencyMode: true,
          });

          hlsRef.current = hls;

          hls.loadSource(src);
          hls.attachMedia(video);

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (autoPlay) {
              video.play().catch((error) => {
                console.error("Auto-play was prevented:", error);
              });
            }
          });

          hls.on(Hls.Events.LEVEL_LOADED, () => {
            // Optional: Additional logic when level is loaded
          });

          hls.on(Hls.Events.ERROR, (_, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  handleError(
                    "A network error occurred. Please check your connection.",
                    "Network Error"
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  handleError(
                    "A media error occurred. Attempting to recover...",
                    "Media Error"
                  );
                  hls.recoverMediaError();
                  break;
                default:
                  handleError(
                    `An unexpected error occurred: ${data.details}`,
                    "HLS Error"
                  );
                  hls.destroy();
                  break;
              }
            }
          });

          // Listen to video events to set isVideoReady
          const handleCanPlayThrough = () => {
            setIsVideoReady(true);
            setIsLoading(false);
          };

          const handleLoadedData = () => {
            setIsVideoReady(true);
            setIsLoading(false);
          };

          video.addEventListener("canplaythrough", handleCanPlayThrough);
          video.addEventListener("loadeddata", handleLoadedData);

          // Cleanup function to remove event listeners
          const cleanup = () => {
            video.removeEventListener("canplaythrough", handleCanPlayThrough);
            video.removeEventListener("loadeddata", handleLoadedData);
          };

          // Return cleanup function
          return cleanup;
        }
        if (video.canPlayType("application/vnd.apple.mpegurl")) {
          // Native HLS support (e.g., Safari)
          video.src = src;
          video.addEventListener("loadedmetadata", () => {
            setIsLoading(false);
            setIsVideoReady(true);
            if (autoPlay) {
              video.play().catch((error) => {
                console.error("Auto-play was prevented:", error);
              });
            }
          });

          // Cleanup function to remove event listener
          const cleanup = () => {
            video.removeEventListener("loadedmetadata", () => {
              setIsLoading(false);
              setIsVideoReady(true);
              if (autoPlay) {
                video.play().catch((error) => {
                  console.error("Auto-play was prevented:", error);
                });
              }
            });
          };

          return cleanup;
        }
        handleError(
          "HLS is not supported in this browser.",
          "Unsupported Error"
        );
      };

      const cleanupHls = initializeHls();

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy();
          hlsRef.current = null;
        }
        // Remove poster attribute to prevent flash
        video.removeAttribute("poster");
        setIsLoading(false);
        setIsVideoReady(false);
        setError(null);
        if (cleanupHls) cleanupHls();
      };
    }
    // When immersionMode is false or src is absent, ensure HLS is destroyed
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    setIsLoading(false);
    setIsVideoReady(false);
    setError(null);
  }, [immersionMode, src, autoPlay, videoPlayerRef]);

  // Ensure poster is removed when video is ready
  useEffect(() => {
    if (isVideoReady) {
      videoPlayerRef.current?.removeAttribute("poster");
    }
  }, [isVideoReady, videoPlayerRef]);

  // Handle buffering events
  // useEffect(() => {
  //   const video = videoPlayerRef.current;
  //   if (!video) return;

  //   const handleWaiting = () => {
  //     useMediaPlayerStore.getState().setIsBuffering(true);
  //   };

  //   const handlePlaying = () => {
  //     useMediaPlayerStore.getState().setIsBuffering(false);
  //   };

  //   video.addEventListener("waiting", handleWaiting);
  //   video.addEventListener("playing", handlePlaying);

  //   return () => {
  //     video.removeEventListener("waiting", handleWaiting);
  //     video.removeEventListener("playing", handlePlaying);
  //   };
  // }, [videoPlayerRef]);

  // Conditional Rendering based on immersionMode and src
  if (immersionMode) {
    if (src) {
      return (
        <div className={cn("relative w-full h-full", cssClass)}>
          {/* AuroraGPU Overlay */}
          <div
            className={cn(
              "absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 transition-opacity duration-500 ease-in-out",
              isLoading || !isVideoReady
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            )}
            aria-live="polite"
            aria-busy={isLoading || !isVideoReady}
          >
            <AuroraGPU key={colors.join(",")} colorPalette={colors} />
          </div>

          {/* Video Element */}
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <video
            ref={videoPlayerRef}
            poster={poster}
            muted={muted}
            loop={loop}
            className={cn(
              "w-full h-full object-cover transition-opacity duration-500 ease-in-out",
              isVideoReady ? "opacity-100" : "opacity-0"
            )}
            autoPlay={false}
            playsInline
            onClick={onPlayPause}
            onPause={onPlayPause}
            controlsList="nodownload"
            controls={false}
            aria-label="Main video content"
            onError={() => {
              handleError("Failed to load video.", "Video Error");
            }}
          >
            Your browser does not support the video element.
          </video>

          {/* Error Overlay */}
          <ErrorOverlay
            error={error}
            onRetry={() => window.location.reload()}
          />
        </div>
      );
    }
    // Render AuroraGPU fullscreen
    return (
      <div className={cn("relative w-full h-full", cssClass)}>
        <AuroraGPU key={colors.join(",")} colorPalette={colors} />
      </div>
    );
  }
  // Render poster image only
  return (
    <div className={cn("relative w-full h-full", cssClass)}>
      <img
        src={poster}
        alt="Video poster"
        className="w-full h-full object-cover"
      />
    </div>
  );
};

export default memo(MediaPlayerV2VideoController);
